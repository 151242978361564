import React from 'react'
import { Row, Col } from 'antd'
import { ReactSVG } from 'react-svg'

function RequestBids(props) {
    const {flight, setStep, setActiveBid, currentUser, requestBids} = props

    const onClickHandler = (requestBid) => {
        setActiveBid(requestBid)
        setStep('BidInfo')
    }

    return (
        <>
            {requestBids.length > 0? requestBids.map(requestBid =>
                <Row className="flight-item" onClick={() => onClickHandler(requestBid)} key={requestBid.id} >
                    <Col span={24}>
                        <div className="flight-item-header">
                            <div className="d-flex">
                                <img alt='' src={requestBid.pilotDetails.photo || '/images/user-placeholder.png'} className="pilot-profile" />
                                <p className="ml-1">{requestBid.pilotDetails.firstname} {requestBid.pilotDetails.lastname}</p>
                                <ReactSVG src="/images/verified-star.svg" className="verify-icon ml-1" />
                            </div>
                            <div className="d-flex">
                                {/*<Rating
                                      placeholderRating={4.5}
                                      emptySymbol={<ReactSVG src="/images/star-rating-blank.svg" className="rating-icon" />}
                                      placeholderSymbol={<ReactSVG src="/images/star-rating-yellow.svg" className="rating-icon" />}
                                      fullSymbol={<ReactSVG src="/images/star-rating-yellow.svg" className="rating-icon" />}
                                    />
                                    <p className="gray ml-1">4.5</p>*/}
                            </div>
                        </div>
                        <div className="flight-item-body">
                            <div className="d-flex d-space">
                                <div>
                                    <p className="gray">Departure Time</p>
                                    <p>{requestBid.departureTime}</p>
                                </div>
                                {requestBid.pilotDetails.hoursFlown &&
                                    <div>
                                        <p className="gray">Hours Flown</p>
                                        <p>{requestBid.pilotDetails.hoursFlown}</p>
                                    </div>
                                }
                                <div>
                                    <p className="gray">Plane</p>
                                    <p>{requestBid.planeDetails.planeType}</p>
                                </div>
                            </div>
                            <hr className="mh-2" />
                            <div className="d-flex d-space">
                                <h3>${(+requestBid.cost).toFixed(2)}</h3>
                                <p className="gray">{flight.public ? 'Per person' : 'Flight Cost'}</p>
                            </div>
                            {!flight.public && (
                                <div className="d-flex d-space mt-1">
                                    <h3>${(requestBid.cost / requestBid.planeDetails.seatCapacity).toFixed(2)}</h3>
                                    <p className="gray">Per seat</p>
                                </div>
                            )}
                            <div className="d-flex d-space mt-1">
                                <h3>${(+requestBid.costPerAdditionalBag).toFixed(2)}</h3>
                                <p className="gray">Per additional bag</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            ):
            <Row className="flight-item">
                <Col span={24}>
                    No Bids yet.
                </Col>
            </Row>
        }
        </>
    )
}

export default RequestBids
