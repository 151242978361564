import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Row, Col, Select } from 'antd'
import * as utils from '../../../../utils'
import FlyTimePicker from '../../../../components/FlyTimePicker';
import NumberFormat from 'react-number-format';

function RequestBidForm (props) {
    const {setStep, bidInfo, setBidInfo, fleets, pilots, activeRecord} = props
    const [form] = Form.useForm()
    const [tempPrice, setTempPrice] = useState(0);
    const [tempBagPrice, setTempBagPrice] = useState(0);

    const filteredFleets = fleets.filter(data => {
        return activeRecord.planeType.split(', ').includes(data.planeType) ||
            activeRecord.planeType === 'All' ||
            activeRecord.planeType === 'Any';
    });

    const onSubmitHandler = (values) => {
        Object.keys(values).forEach(key => {bidInfo[key] = values[key]})
        setBidInfo(bidInfo)
        setStep('tac')
    }

    useEffect(() => {
        setTempPrice(form.getFieldValue('cost') || 0);
        setTempBagPrice(form.getFieldValue('costPerAdditionalBag') || 0);
    }, [])

    useEffect(() => {
        let bagCnt = 0
        activeRecord.passengers && Object.keys(activeRecord.passengers).map(pkey => {
            let passenger = activeRecord.passengers[pkey]
            const passengerBagCnt = passenger.numberOfBags || 0
            bagCnt += passengerBagCnt > 1 ? passengerBagCnt - 1 : 0
        })

        const plane = form.getFieldValue('plane');
        const price = activeRecord.public ? (tempPrice * plane.seatCapacity) : tempPrice;
        const bagPrice = tempBagPrice * bagCnt;
        form.setFieldsValue({ 'potentialEarnings': Math.round((+price + +bagPrice) * 0.75) })
    }, [tempPrice, tempBagPrice])

    return (
        <Form
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={onSubmitHandler}
            requiredMark={false}
            initialValues={bidInfo}
        >
            <h2 className="t-center">Bid</h2>
            <Form.Item
                label="Registered Plane"
                name="plane"
                rules={[{
                    required: true,
                    validator: (rule, value, callback) => utils.validations.validatePlaneAvailableSeats(rule, value, callback, 'Registered Plane', activeRecord, filteredFleets)
                }]}
            >
                <Select placeholder="Pick one" className="fly-select" showSearch={true} options={filteredFleets} allowClear/>
            </Form.Item>
            <Form.Item label="Registered Pilot" name="pilot" rules={[{ required: true, message: 'This field is required' }]}>
                <Select placeholder="Pick one" className="fly-select" showSearch={true} options={pilots} allowClear/>
            </Form.Item>
            <Form.Item
                label={activeRecord.public ? 'Cost per Person' : 'Flight Cost'}
                name='cost'
                rules={[{ required: true, validator: (rule, value, callback) =>
                        utils.validations.validateNumber(rule, value, callback, activeRecord.public ? 'Cost per Person' : 'Flight Cost', true, 1) }]}
            >
                <Input
                    type="number"
                    className="fly-input"
                    min="1"
                    placeholder="Price"
                    prefix="$"
                    onChange={(e) => setTempPrice(e.target.value)}
                />
            </Form.Item>
            <Form.Item label="Cost per Additional bag" name='costPerAdditionalBag'
                rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Cost per additional bag', true, 0) }]}>
                <Input type="number" className="fly-input" min="0" placeholder="Price" prefix="$" onChange={(e) => setTempBagPrice(e.target.value)}/>
            </Form.Item>
            <Form.Item label="Potential Earnings" name='potentialEarnings' >
                <NumberFormat className="fly-input potential-earnings" thousandSeparator={true} prefix="$ " readOnly />
            </Form.Item>
            {bidInfo.flexible && <>
                <h4 className="mt-5">This flight is flexible</h4>
                <p className="label mt-2">Please enter a departure time {bidInfo.flexible === 'Sunset'? "after": "before"} 12:00 PM</p>
            </>}
            <Row>
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Departure Time"
                        name='departureTime'
                        rules={
                            bidInfo.flexible
                                ? [{ required: true, validator: (rule, value, callback) => utils.validations.validateTimeToday(rule, value, callback, 'Departure time', true, bidInfo.flexible, activeRecord)}]
                                : [{ required: true }]
                        }
                    >
                        <FlyTimePicker
                            format="hh:mm a"
                            placeholder="HH:MM PP"
                            className="fly-date-picker"
                            disabled={!bidInfo.flexible}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="mt-2 d-space">
                <Col><Button type="primary" className="fly-btn green-btn bordered" onClick={() => setStep('info')}>BACK</Button></Col>
                <Col><Button type="primary" htmlType="submit" className="fly-btn green-btn">REVIEW</Button></Col>
            </Row>
        </Form>
    )
}

export default RequestBidForm
