import axios from 'axios';

// const baseUrl = 'http://127.0.0.1:5001/flymingo---dev/us-central1/api_v2/'
const baseUrl = `https://us-central1-flymingo---${process.env.REACT_APP_SECRET_CODE === 'Prod' ? 'prod' : 'dev'}.cloudfunctions.net/api_v2/`

const requests = {
    get: (url) => axios({ url: `${baseUrl}${url}`, method: 'get'}),
    post: (url, data) => axios({url: `${baseUrl}${url}`, method: 'post', data}),
    put: (url, data) => axios.put(`${baseUrl}${url}`, data),
    patch: (url, data) => axios.patch(`${baseUrl}${url}`, data),
    del: (url) => axios.delete(`${baseUrl}${url}`),
}

export const ApiManager = {
    createUser: (user) => requests.post('user', {user: user}),
    updateUser: (user) => requests.put('user', {user: user}),
    changeEmail: (data) => requests.post(`change-email-request?uid=${data.uid}&email=${data.email}&oldEmail=${data.oldEmail}`),
    deleteUser: (uid) => requests.del(`user/${uid}`),
    verifyEmailRequest: (data) => requests.post('user/verify-email-request', data),
    sendEmail: (data) => requests.post('send-email-notification', data),
    scheduleRequest: (data) => requests.post('schedule-request', data),
    scheduleLicenseExpiration: (data) => requests.post('schedule-license-expiration', data),
    getPilotRequests: (data) => requests.get(`pilot/requests?uid=${data.uid}&rid=${data.rid}`),
    pilotFlights: (data) => requests.post('pilot/flights', data),
    getPilotWallet: (data) => requests.get(`pilot/wallet?uid=${data.uid}`),
    getPilotOverview: (data) => requests.get(`pilot/overview?uid=${data.uid}`),
    getFlyerFlights: (data) => requests.get(`flyer/flights?uid=${data.uid}&ftype=${data.ftype}`),
    getFlyerFlightDetails: (data) => requests.get(`flyer/flight-details?uid=${data.uid}&fid=${data.fid}&rid=${data.rid}`),
    flyerRequests: (data) => requests.post('flyer/requests', data),
    flyerDepartures: (data) => requests.post('flyer/departures', data),
    sendSlackAlert: (data) => requests.post('send-slack-alert', data),
    sendRequestToBook: (data) => requests.post('flyer/request-to-book', data),
    requestFlight: (data) => requests.post('flyer/request-a-flight', data),
    smartCheckIn: (data) => requests.post('flyer/smart-check-in', data),
    flyerCancelFlight: (data) => requests.post('flyer/cancel-flight', data),
    flyerEditFlight: (data) => requests.post('flyer/edit-flight', data),
    acceptBid: (data) => requests.post('accept-bid', data),
    acceptPlace: (data) => requests.post('accept-place', data),
    getBids: (data) => requests.get(`bids?id=${data.id}`, data),
    createPaymentIntent: (data) => requests.post('create-payment-intent', data),
    kanooPaymentIntent: (data) => requests.post('kanoo-payment-intent', data),
    addNotification: (data) => requests.post('flyer/add-notification', data),
    completeRequests: (data) => requests.post('complete-requests', data),
    sendExpoAppNotification: (data) => requests.post('send-expo-notification', data),
    getReceipt: (data) => requests.get(`receipt?uid=${data.uid}&tid=${data.tid}`, data),
    saveUserEmailForCountryExpandNotification: (data) => requests.post('save-user-email-for-country-expand-notification', data),
    changePhone: (data) => requests.post(`change-phone-request?uid=${data.uid}&phoneNumber=${data.phoneNumber}`),
}

export default ApiManager
