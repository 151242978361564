import React, { useEffect, useState } from 'react'
import { Form, Button, Tabs, Table, Space, Tooltip, Modal } from 'antd'
import NumberFormat from 'react-number-format'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import RequestDetailsModal from  './RequestDetailsModal'
import { firestore } from '../../../firebaseConfig'
import './index.scss'
import { ApiManager } from "../../../service"

const { TabPane } = Tabs

function Requests(props) {
    const [form] = Form.useForm()
    const {history, currentUser} = props
    const [activeRecord, setActiveRecord] = useState()
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)
    const [step, setStep] = useState('info')
    const [requests, setRequests] = useState([])
    const [loading, setLoading] = useState()
    const [filters, setFilters] = useState({})
    const [fleets, setFleets] = useState([])
    const [pilots, setPilots] = useState([])
    const [fleetsDict, setFleetsDict] = useState({})
    const [pilotsDict, setPilotsDict] = useState({})

    useEffect(() => {
        if (activeRecord) {
            setIsDetailsModalVisible(true)
            setStep('info')
        }
        else
            setIsDetailsModalVisible(false)
    }, [activeRecord])

    useEffect(() => {
        loadData()
        loadAssets()
    }, [])

    const loadData = async () => {
        setLoading(true)
        let rid = new URLSearchParams(history.location.search).get('rid')
        ApiManager.getPilotRequests({uid: currentUser.uid, rid: rid}).then(res => {
            let activeRecord = res.data.body.activeRequest
            setRequests(res.data.body.requests)
            if (rid && activeRecord) {
                setActiveRecord(activeRecord)
            }
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const loadAssets = async () => {
        const fSnapshot = await firestore.collection('fleets')
            .where('uid', '==', currentUser.uid).where('status', '==', true).get()
        let fList = []
        if (!fSnapshot.empty) {
            fSnapshot.forEach(doc => {
                let fItem = doc.data()
                fItem.id = doc.id
                fItem.label = fItem.tailNumber
                fItem.value = doc.id
                if (fItem.verified === 'done' && !fItem.deletedByUser) {
                    fList.push(fItem)
                    fleetsDict[doc.id] = fItem
                }
            })
            setFleets(fList)
            setFleetsDict(fleetsDict)
        }
        const pSnapshot = await firestore.collection('pilots').where('uid', '==', currentUser.uid).get()
        let pList = []
        if (!pSnapshot.empty) {
            pSnapshot.forEach(doc => {
                let pItem = doc.data()
                if (pItem.licenseExpirationDate !== '')
                    pItem.licenseExpirationDate = utils.getDate(pItem.licenseExpirationDate)
                pItem.id = doc.id
                pItem.label = `${pItem.firstname} ${pItem.lastname}`
                pItem.value = doc.id
                if (pItem.verified === 'done' && !pItem.deletedByUser) {
                    pList.push(pItem)
                    pilotsDict[doc.id] = pItem
                }
            })
            setPilots(pList)
            setPilotsDict(pilotsDict)
        }
    }

    const handleCancel = () => {
        setActiveRecord()
        setIsDetailsModalVisible(false)
        history.push('/dashboard/requests')
    }

    const handleOk = () => {
        setActiveRecord()
        setIsDetailsModalVisible(false)
        history.push('/dashboard/requests')
    }

    const onSubmitHandler = (values) => {
        console.log(values)
        setFilters(values)
    }

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 15,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const removeModal = () => {
        Modal.confirm({
            title: '',
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3>Remove the flight</h3>
                <p>Are you sure you want to cancel your flight <b>Nassau - Staniel Cay</b>?</p>
            </div>,
            okText: 'REMOVE FLIGHT',
            onOk() {
              utils.openNotification('Removed successfully!', true)
            },
        })
    }

    const columns = [
        {
            title: 'Request #',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => <Space className="request-number">
                <p>{text}</p>
            </Space>,
            width: '12%'
        }, {
            title: 'Departure Date/Time',
            dataIndex: 'departureDate',
            key: 'departureDate',
            render: (text, record) => <Space>
                <ReactSVG src="/images/calendar.svg" className="label-icon" />
                <p className="mr-1">{text} {record.departureTime}</p>
            </Space>,
        }, {
            title: 'Departure',
            dataIndex: 'departureAirport',
            key: 'departureAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon" />
                    {text
                        ? <p>{text}</p>
                        : (
                            <div>
                                <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                    <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                    {record.departureLocation?.latitude}
                                </p>
                                <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                    <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                    {record.departureLocation?.longitude}
                                </p>
                            </div>
                    )}
            </Space>,
        }, {
            title: 'Arrival',
            dataIndex: 'arrivalAirport',
            key: 'arrivalAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon" />
                {text
                    ? <p>{text}</p>
                    : (
                        <div>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                {record.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                {record.arrivalLocation?.longitude}
                            </p>
                        </div>
                    )
                }
            </Space>,
        }, {
            title: 'Plane Type',
            dataIndex: 'planeType',
            key: 'planeType',
            render: (text, record) => <Space>
                <ReactSVG src={`images/${utils.getPlaneType(text)}.svg`} className="label-icon plane-type-icon"/>
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Passengers',
            dataIndex: 'passengers',
            key: 'passengers',
            render: (text, record) => <p>{Object.keys(text).length}</p>,
        }, {
            title: '# of Bags',
            dataIndex: 'numberOfBags',
            key: 'passengers'
        }, {
            title: '# of Pets',
            dataIndex: 'numberOfPets',
            key: 'numberOfPets',
            render: (text, record) => <p>{text? text: 0}</p>,
        }, {
            title: 'Total Weight',
            dataIndex: 'totalWeight',
            key: 'weight',
            render: (text, record) => <Space>
                <NumberFormat className="fly-text" thousandSeparator={true} value={text} readOnly/>
            </Space>,
        }, {
            title: 'Privacy',
            dataIndex: 'public',
            key: 'public',
            render: (text, record) => <Space>
                <Tooltip className="fly-tooltip" title={<p>{text? 'Public': 'Private'}</p>} color="#90C4C2" placement="left">
                    <ReactSVG src={`/images/${text? 'eye': 'closed-eye'}.svg`} className="label-icon" />
                </Tooltip>
            </Space>,
        },  {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <Space>
                { text === "awaiting" && <a className="status-btn">Awaiting Flyer</a> }
                { text === "pending" && <a className="status-btn warning">Pending Bid</a> }
                { text === "accepted" && <a className="status-btn success">Accepted</a> }
                { text === "declined" && <a className="status-btn danger">Declined</a> }
                { text === "expired" && <a className="status-btn danger">Expired</a> }
            </Space>
        }
    ]

    return (
        <div className="content fly-board request-board">
            <div className="content-header">
                <h2 className="mb-0">Requests</h2>
                {isDetailsModalVisible &&
                    <RequestDetailsModal
                        isModalVisible={isDetailsModalVisible}
                        handleCancel={handleCancel}
                        handleOk={handleOk}
                        step={step}
                        setStep={setStep}
                        activeRecord={activeRecord}
                        fleets={fleets}
                        fleetsDict={fleetsDict}
                        pilots={pilots}
                        pilotsDict={pilotsDict}
                        currentUser={currentUser}
                        loadData={loadData}
                    />
                }
                <Button type="primary" className="fly-btn"></Button>
            </div>
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                >
                    <div className="form-content">
                        <Table
                            columns={columns}
                            dataSource={requests}
                            pagination={pagination}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        setActiveRecord(record)
                                    }
                                }}
                            }
                            loading={loading}
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Requests
