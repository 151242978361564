import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Form, Input, Button, Radio, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import FlyActions from '../../../redux/common'
import { firestore } from "../../../firebaseConfig"
import { STRIPE_PAYMENT_COUNTRY } from "../../../constants";

function SetupPayoutMethod(props) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const {setSetupPayoutMethod, currentUser} = props
    const [payoutMethodType, setPayoutMethodType] = useState(currentUser.profile.payment && currentUser.profile.payment.type)
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = async (values) => {
        setLoading(true)
        let payment = currentUser.profile.payment || {}
        Object.keys(values).forEach(key => { payment[key] = values[key] || '' })
        await firestore.collection('users').doc(currentUser.uid).update({ payment: payment })
        dispatch(FlyActions.onLoadUser(currentUser))
        setLoading(false)
        utils.openNotification('Saved successfully!')
        setSetupPayoutMethod(false)
    }

    const onChangePayoutMethodType = (e) => {
        setPayoutMethodType(e.target.value)
    }

    return (
        <div className="content wallet-board edit-view">
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={currentUser.profile.payment}
                >
                    <div className="form-content">
                        <div className="form-header d-flex d-space mb-4">
                            <Space><ReactSVG src="/images/back.svg" className="back-icon" onClick={() => setSetupPayoutMethod(false)}/>
                                <h2 className="mb-0">Setup Payout Method</h2>
                            </Space>
                            <Form.Item className="mb-0">
                                <Button type="primary" htmlType="submit"  className="fly-btn" loading={loading}>
                                    SAVE
                                </Button>
                            </Form.Item>
                        </div>
                        <div className="form-body">
                            <h3>Payment Method</h3>
                            <Row>
                                <Col md={24} xs={12}>
                                    <Form.Item label="" name='type'>
                                        <Radio.Group
                                            options={
                                                currentUser?.profile?.country === STRIPE_PAYMENT_COUNTRY
                                                    ? utils.resources.payoutsMethodsUS
                                                    : utils.resources.payoutMethods
                                            }
                                            size='large'
                                            className="fly-radio-grp"
                                            onChange={onChangePayoutMethodType}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex d-space">
                                <Col md={11} xs={24}>
                                    <Form.Item label="Email" name='email'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]
                                        }>
                                        <Input type="email" className="fly-input" placeholder="Enter email" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* payoutMethodType === 'Kanoo Pay'?
                                :
                                <>
                                    <Row className="d-flex d-space">
                                        <Col md={11} xs={24}>
                                            <Form.Item label="Beneficiary Account Number" name='beneficiaryAccountNumber'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Beneficiary Account Number', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter your bank account number" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={11} xs={24}>
                                            <Form.Item label="SWIFT or IBN" name='swiftOrIBN'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'SWIFT or IBN', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter the SWIFT code" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex d-space">
                                        <Col md={11} xs={24}>
                                            <Form.Item label="Bank Name" name='bankName'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Bank Name', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter the bank name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={11} xs={24}>
                                            <Form.Item label="Street Address" name='address'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateAddress(rule, value, callback, 'Street Address', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter street address" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex d-space">
                                        <Col md={11} xs={24}>
                                            <Form.Item label="Apt, Suite" name='aptSuite'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateTextAndNumber(rule, value, callback, 'Apt, Suite', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Ex. #123" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={11} xs={24}>
                                            <Form.Item label="City" name='city'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'City', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter your city/province" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="d-flex d-space">
                                        <Col md={11} xs={24}>
                                            <Form.Item label="State/Island" name='state'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'State/Island', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter your country/state" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={11} xs={24}>
                                            <Form.Item label="Zip/Post Code*" name='zipcode'
                                                rules={[{
                                                    required: true,
                                                    validator: (rule, value, callback) => utils.validations.validateUSZipCode(rule, value, callback, 'Zip/Post Code*', true) }]
                                                }>
                                                <Input type="text" className="fly-input" placeholder="Enter zip/post code" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            */}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default SetupPayoutMethod
