import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Select, Radio, Space, Drawer } from 'antd'
import Upload from 'rc-upload'
import { InfoCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import NumberFormat from 'react-number-format'
import { firestore, serverTimestamp, firestorage } from "../../../firebaseConfig"
import { ApiManager } from "../../../service"
import Loader from "react-loader-spinner"
import prepareNumericalValue from '../../../utils/prepareNumericalValue';

const requiredFields = ['nickname', 'tailNumber', 'year', 'planeMake', 'seatCapacity', 'planeType'];

function AddPlaneDrawer(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState()
    const [uploadedFilesAOC, setUploadedFilesAOC] = useState({files: [], uploading: false})
    const [uploadedFilesInspection, setUploadedFilesInspection] = useState({files: [], uploading: false})
    const [uploadedFilesPhotos, setUploadedFilesPhotos] = useState({files: [], uploading: false})
    const [fileToUploadAOC, setFileToUploadAOC] = useState({});
    const [fileToUploadInspection, setFileToUploadInspection] = useState({});
    const [fileToUploadPhotos, setFileToUploadPhotos] = useState({});
    const {drawerVisible, closeDrawerHandler, activeRecord, currentUser, loadData} = props
    const [photoError, setPhotoError] = useState(utils.resources.planePhotos.reduce((acc, photo) => {
        acc[photo.value] = false;
        return acc;
    }, {}))
    const isPhotoError = Object.values(photoError).some(value => !!value);
    
    useEffect(() => {
        if (activeRecord) {
            setUploadedFilesAOC({
                files: (!activeRecord.AOCCertificate || typeof (activeRecord.AOCCertificate) === 'string')
                    ? []
                    : activeRecord.AOCCertificate,
                uploading: false
            })
            setUploadedFilesInspection({
                files: (!activeRecord.inspectionCertificate || typeof (activeRecord.inspectionCertificate) === 'string')
                    ? []
                    : activeRecord.inspectionCertificate,
                uploading: false
            })
            setUploadedFilesPhotos({
                files: (!activeRecord.photos || typeof (activeRecord.photos) === 'string')
                    ? []
                    : activeRecord.photos,
                uploading: false
            })
        }
    }, [])

    const onSubmitHandler = async (values) => {
        setLoading(true)
        let fData = Object.assign({}, values)
        fData.uid = currentUser.uid
        Object.keys(values).forEach(key => {
            if (fData[key] === undefined) fData[key] = ''
        })
        fData.AOCCertificate = uploadedFilesAOC.files || []
        fData.inspectionCertificate = uploadedFilesInspection.files || []
        
        let isAllPlanePhotosAdded = true;
        
        for (const side of utils.resources.planePhotos.map(photo => photo.value)) {
            const photoExists = uploadedFilesPhotos.files.some(photo => photo.side === side);
            
            if (!photoExists) {
                setPhotoError(prevState => ({
                    ...prevState,
                    [side]: true
                }));
                isAllPlanePhotosAdded = false;
            }
        }
        
        if (!isAllPlanePhotosAdded) {
            setLoading(false);
            return;
        }
        
        fData.photos = uploadedFilesPhotos.files || []
        delete fData.planePhoto;

        let fSnapshot = await firestore.collection('fleets').where("tailNumber", "==", values.tailNumber).get()
        if (activeRecord) {
            if (activeRecord.tailNumber !== values.tailNumber && !fSnapshot.empty) {
                utils.openNotification('Tail Number should be unique', false)
                setLoading(false)
                return
            }
            fData.verified = "pending"
            await firestore.collection('fleets').doc(activeRecord.id).update(fData)
        } else {
            if (!fSnapshot.empty) {
                utils.openNotification('Tail Number should be unique', false)
                setLoading(false)
                return
            }
            fData.createdAt = serverTimestamp
            const pSnapshot = await firestore.collection('fleets').add(fData)
            currentUser.profile.fleets.push(pSnapshot.id)
            await firestore.collection('users').doc(currentUser.uid).update({fleets: currentUser.profile.fleets})
                .then(() => {
                    utils.pushDataLayer({ event: 'plane_added' });
                    ApiManager.sendSlackAlert({
                        text: `🔔 plane added \nTail number: ${fData?.tailNumber || ''} \nSeat capacity ${fData?.seatCapacity || ''}`
                    });
                })
            ApiManager.sendEmail({type: "fleetRequest", id: pSnapshot.id, uid: currentUser.uid, collection: "fleets",
                verified: "pending", reason: ""})
        }
        utils.openNotification(`${activeRecord? 'Saved': 'Added'} successfully!`, true)
        setLoading(false)
        loadData()
        closeDrawerHandler(false)
    }

    useEffect(() => {
        if (Object.keys(fileToUploadAOC).length !== 0) {
            setUploadedFilesAOC({
                ...uploadedFilesAOC,
                files: [...uploadedFilesAOC.files, fileToUploadAOC],
                uploading: false
            })
        }
    }, [fileToUploadAOC])

    const customFileAOCUploadHandler = async ({ onError, onSuccess, file }) => {
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesAOC({...uploadedFilesAOC, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/aoc-certificate-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadAOC({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesAOC({...uploadedFilesAOC, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    useEffect(() => {
        if (Object.keys(fileToUploadInspection).length !== 0) {
            setUploadedFilesInspection({
                ...uploadedFilesInspection,
                files: [...uploadedFilesInspection.files, fileToUploadInspection],
                uploading: false
            })
        }
    }, [fileToUploadInspection])

    const customFileInspectionUploadHandler = async ({ onError, onSuccess, file }) => {
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesInspection({...uploadedFilesInspection, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/inspection-certificate-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadInspection({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesInspection({...uploadedFilesInspection, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    useEffect(() => {
        if (Object.keys(fileToUploadPhotos).length !== 0) {
            setUploadedFilesPhotos({
                ...uploadedFilesPhotos,
                files: [...uploadedFilesPhotos.files, fileToUploadPhotos],
                uploading: false
            })
        }
    }, [fileToUploadPhotos])
    
    const onRemoveFilePhoto = (side) => {
        setPhotoError({ ...photoError, [side]: false });

        setUploadedFilesPhotos({
            ...uploadedFilesPhotos,
            files: uploadedFilesPhotos.files.filter(file => file.side !== side)
        })
    }
    
    const customFilePhotosUploadHandler = async ({ onError, onSuccess, file, side }) => {
        setPhotoError({ ...photoError, [side]: false });
        
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            setUploadedFilesPhotos({...uploadedFilesPhotos, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/photo-${utils.generateRandomKeys()}.png`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                if (uploadedFilesPhotos.files.find(file => file.side === side)) {
                    onRemoveFilePhoto(side);
                }
                setFileToUploadPhotos({ path: filePath, name: file.name, side })
            } catch(e) {
                setUploadedFilesPhotos({...uploadedFilesPhotos, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    const initialValues = activeRecord ? {...activeRecord,
        planeType: activeRecord && activeRecord.planeType
    } : {}
    
    const onChangeSeatCapacity = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        form.setFieldValue('seatCapacity', newVal);
    }

    return (
        <Drawer
            title={`${activeRecord? 'Edit': 'Add a'} plane`}
            placement="right"
            onClose={closeDrawerHandler}
            visible={drawerVisible}
            width='32.31rem'
            extra={
                <Space>
                    <Button onClick={closeDrawerHandler}>Cancel</Button>
                    <Button type="primary" onClick={closeDrawerHandler}>
                        OK
                    </Button>
                </Space>
            }
            destroyOnClose={true}
        >
            <Form
                className="content"
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={true}
                preserve={false}
                initialValues={initialValues}
            >
                <div className="form-content">
                    <div className="form-body">
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Nickname" name='nickname'
                                    rules={[{
                                        validator: (rule, value, callback) => utils.validations.validateTextAndNumber(rule, value, callback, 'Nickname', true)
                                    }]}>
                                    <Input type="text" className="fly-input" placeholder="Name your plane" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/>}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Tail Number" name='tailNumber'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateTailNumber(rule, value, callback, 'Tail number', true) }]
                                    }>
                                    <Input type="text" className="fly-input" placeholder="Add tail number" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} className="pl-1">
                                <Form.Item label="Year" name='year'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateYear(rule, value, callback, 'Year', true) }]
                                    }>
                                    <Input type="text" className="fly-input" placeholder="Year" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            {/*<Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Price Per Person" name='pricePerPerson'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Price', true, 0) }]
                                    }>
                                    <Input type="number" className="fly-input" min="1" placeholder="Price" prefix="$" />
                                </Form.Item>
                            </Col>*/}
                            <Col md={24} xs={24}>
                                <Form.Item label="Plane Make" name='planeMake'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        options={utils.resources.planeManufacturers}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Seat Capacity" name='seatCapacity'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Seat capacity', true, 0, 19) }]
                                    }>
                                    <Input type="number" className="fly-input" min="1" max="19" placeholder="Seat" onChange={onChangeSeatCapacity}/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} className="pl-1">
                                <Form.Item label="Max Weight Capacity" name='maxWeightCapacity' className="max-weight-capacity">
                                    <NumberFormat className="fly-input" thousandSeparator={true} placeholder="Weight" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="AOC Certificate">
                                    {uploadedFilesAOC.files.length !== 0 && (
                                        <div className="ant-upload-select uploaded">
                                            <CheckOutlined style={{ color: '#ffffff'}}/>
                                            <p>Uploaded!</p>
                                            <EditOutlined
                                                className="p-right"
                                                onClick={() => setUploadedFilesAOC({
                                                    ...uploadedFilesAOC,
                                                    files: []
                                                })}
                                            />
                                        </div>
                                    )}
                                    {uploadedFilesAOC.uploading && (
                                        <div className="ant-upload-select uploading">
                                            <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                        </div>
                                    )}
                                    <div style={{
                                        position: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'absolute',
                                        zIndex: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 1 : -1,
                                        opacity: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 1 : 0,
                                        pointerEvents: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'none',
                                        touchAction: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'none'
                                    }}>
                                        <Upload
                                            className="ant-upload ant-upload-select"
                                            customRequest={customFileAOCUploadHandler}
                                            showUploadList={false}
                                            multiple={true}
                                            accept=".png,.jpg,.jpeg,.pdf"
                                        >
                                            <p>Upload</p>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Inspection certificate">
                                    {uploadedFilesInspection.files.length !== 0 && (
                                        <div className="ant-upload-select uploaded">
                                            <CheckOutlined style={{ color: '#ffffff'}}/>
                                            <p>Uploaded!</p>
                                            <EditOutlined
                                                className="p-right"
                                                onClick={() => setUploadedFilesInspection({
                                                    ...uploadedFilesInspection, files: []
                                                })}
                                            />
                                        </div>
                                    )}
                                    {uploadedFilesInspection.uploading && (
                                        <div className="ant-upload-select uploading">
                                            <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                        </div>
                                    )}
                                    <div style={{
                                        position: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'absolute',
                                        zIndex: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 1 : -1,
                                        opacity: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 1 : 0,
                                        pointerEvents: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'none',
                                        touchAction: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'none'
                                    }}>
                                        <Upload
                                            className="ant-upload ant-upload-select"
                                            customRequest={customFileInspectionUploadHandler}
                                            showUploadList={false}
                                            multiple={true}
                                            accept=".png,.jpg,.jpeg,.pdf"
                                        >
                                            <p>Upload</p>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row className="d-flex">
                            <Col span={12} className="pr-2">
                                <Form.Item label="IFR Rating" name='ifrRating'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12} className="pl-2">
                                <Form.Item label="VFR Rating" name='vfrRating'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Choose plane type" name='planeType'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Radio.Group size="large" className="plane-type-radio-grp">
                                        {
                                            utils.resources.planeTypes.map((plane, idx) =>
                                                <Radio.Button value={plane.label} key={plane.value}>
                                                    <div className="d-block">
                                                        <ReactSVG src={`images/${plane.value}.svg`} />
                                                        <p>{plane.label}</p>
                                                    </div>
                                                </Radio.Button>
                                            )
                                        }
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col span={12} className="pr-2">
                                <Row className="d-flex switch-item">
                                    <Col span={8}>
                                        <p>Verified</p>
                                    </Col>
                                    <Col span={16} className="t-right">
                                        <Form.Item label="" name='verified' valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row className="d-flex switch-item">
                                    <Col span={8}>
                                        <p>Status</p>
                                    </Col>
                                    <Col span={16} className="t-right">
                                        <Form.Item label="" name='status' valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Form.Item
                                label="Upload Plane Photo"
                                name='planePhoto'
                                className={`add-plane-title${isPhotoError ? ' add-plane-title-error' : ''}`}
                                rules={[{required: false, message: 'This field is required'}]}
                            >
                                <div className='add-plane-wrapper'>
                                    {utils.resources.planePhotos.map((side, idx) => {
                                        return (
                                            uploadedFilesPhotos.files.find(file => file.side === side.value)
                                                ? (
                                                    <div key={idx} className='add-plane-container'>
                                                        <div
                                                            className='delete-plane-btn-wrapper'
                                                            onClick={() => onRemoveFilePhoto(side.value)}
                                                        >
                                                            <ReactSVG src={`images/deleteSmall.svg`}/>
                                                        </div>
                                                        <img
                                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                            src={uploadedFilesPhotos.files.find(file => file.side === side.value).path}
                                                            alt={side.label}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Upload
                                                        customRequest={(props) => customFilePhotosUploadHandler({
                                                            ...props,
                                                            side: side.value
                                                        })}
                                                        showUploadList={false}
                                                        multiple={true}
                                                        accept=".png,.jpg,.jpeg"
                                                        key={idx}
                                                    >
                                                        <div
                                                            className='add-plane-container'
                                                            style={{
                                                                borderColor: photoError[side.value] ? '#e64c25' : '#458796',
                                                                backgroundColor: photoError[side.value] ? '#E24D591A' : '#fff',
                                                            }}
                                                        >
                                                            <ReactSVG src={`images/addPhoto.svg`}/>
                                                            <p>{side.label}</p>
                                                        </div>
                                                    </Upload>
                                                )
                                        )
                                    })}
                                </div>
                                {isPhotoError && <div className='ant-form-item-explain-error'>This field is required</div>}
                            </Form.Item>
                        </Row>
                    </div>
                </div>
                <Row className="form-footer mt-5">
                    <Col span={24}>
                        <Form.Item shouldUpdate>
                            {() => {
                                let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || (!activeRecord && !form.isFieldsTouched(requiredFields, true)) || isPhotoError
                                return (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="fly-btn w-full"
                                        disabled={invalid}
                                        loading={loading}
                                    >
                                        {`${activeRecord? 'SAVE': 'ADD'} PLANE`}
                                    </Button>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default AddPlaneDrawer
