import React, { useState } from 'react'
import { Row, Col, Form, Button, Radio, Checkbox } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'

function Step2(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlightValues} = props
    const [noPreference, setNoPreference] = useState(flight.noPreference)

    const onSubmitHandler = (values) => {
        setFlightValues(values)
        setStep(3)
    }

    const onChange = (checkedValues) => {
        setNoPreference(checkedValues)
    }

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Plane Type</p>
                        {(!noPreference || noPreference?.length === 0) &&
                            <Row>
                                <Col md={24} xs={24}>
                                    <Form.Item label="" name='planeType'
                                        rules={[{ required: true, message: 'This field is required' }]}>
                                        <Radio.Group size="large" className="plane-type-radio-grp">
                                            {
                                                utils.resources.planeTypes.map((plane, idx) =>
                                                    <Radio.Button value={plane.label} key={plane.value}>
                                                        <div className="d-block">
                                                            <ReactSVG src={`images/${plane.value}.svg`} />
                                                            <p>{plane.label}</p>
                                                        </div>
                                                    </Radio.Button>
                                                )
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                   {/* <Form.Item label="" name='planeType'>
                                       <Checkbox.Group className="fly-checkbox plane-type">
                                           {
                                               utils.resources.planeTypes.map((plane, idx) =>
                                                   <Checkbox value={plane.label} key={plane.value}>
                                                       <div className="d-block">
                                                           <ReactSVG src={`images/${plane.value}.svg`} />
                                                           <p>{plane.label}</p>
                                                       </div>
                                                   </Checkbox>
                                               )
                                           }
                                       </Checkbox.Group>
                                   </Form.Item>*/}
                                </Col>
                            </Row>
                        }
                        <Row className="mt-3">
                            <Col span={24}>
                               <Form.Item name='noPreference'>
                                    <Checkbox.Group className="fly-checkbox" options={[{label: 'No preference', value: true}]}
                                        onChange={onChange}/>
                               </Form.Item>
                           </Col>
                        </Row>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default Step2
