import { COMMON } from '../constants'

const initState = {
  currentUser: null,
  appLoaded: false
}

const FlyReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case COMMON.USER_LOADED:
      return {
        ...state,
        currentUser: action.user,
        appLoaded: true
      }

    case COMMON.PAGE_LOADED:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default FlyReducer
