import React, { useEffect, useState } from 'react'
import { Row, Col, Spin } from 'antd'
import { ReactSVG } from 'react-svg'
import RequestBids from './RequestBids'
import RequestBidInfo from './RequestBidInfo'
import RequestDone from './RequestDone'
import Review from '../../RequestFlight/Review'
import ApiManager from '../../../../service';

function FlightDetails(props) {
    const {history, currentUser, setActiveRecord, setActiveNav} = props
    const [loading, setLoading] = useState()
    const [requestBids, setRequestBids] = useState([])
    const [step, setStep] = useState('Details')
    const [flight, setFlight] = useState()
    const [activeBid, setActiveBid] = useState()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true);
        setFlight(props.flight);
        await ApiManager.getBids({ id: props.flight.id })
          .then((res) => {
              setRequestBids(res.data.body.bids);
          });
        setLoading(false);
    }

    const prevStepHandler = () => {
        if (step === "Details")
            setActiveRecord()
        if (step === "Bids")
            setStep('Details')
        if (step === "BidInfo") {
            setStep('Bids')
            setActiveBid()
        }
    }

    const setFlightValues = (values) => {
        let temp = flight;
        Object.keys(values).forEach(key => {temp[key] = values[key]})
        setFlight(temp)
    }

    const params = {
        history, step, setStep,
        flight, setFlight, setFlightValues,
        activeBid, setActiveBid,
        loading,
        setActiveRecord, currentUser,
        setActiveNav, requestBids
    }

    let renderPage = ''
    let pageTitle = 'Flight Request'
    let headerStyle = 'client-header'
    let bodyStyle = 'client-body'

    switch (step) {
        case 'Bids':
            renderPage = <RequestBids {...params} />
            pageTitle = 'Flight Bids'
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray list height-100-percent'
            break
        case 'BidInfo':
            renderPage = <RequestBidInfo {...params} />
            pageTitle = 'Bid Details'
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray height-100-percent'
            break
        case 'Done':
            renderPage = <RequestDone {...params} />
            pageTitle = ''
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray height-100-percent'
            break
        default:
            renderPage = <Review {...params} external={true} noBids={requestBids?.length === 0} />
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray height-100-percent'
            break
    }

    if (!flight)
        return ''

    return (
        <div className="content flight-details contents flexWrapper">
            <div className={headerStyle}>
                <div className="header-item">
                    {pageTitle && <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                    <p>{pageTitle}</p>
                    <div className="step-marker">
                    </div>
                </div>
            </div>
            <div className={bodyStyle}>
                {loading ?
                    <Row className="m-auto">
                        <Col span={24} className="t-center">
                            <Spin />
                        </Col>
                    </Row>
                    : renderPage
                }
            </div>
        </div>
    )
}

export default FlightDetails
