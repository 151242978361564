import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Redirect } from "react-router-dom"
import { useSelector } from 'react-redux'
import './index.scss'
import { Button, Layout, Modal } from 'antd'
import { FlyHeader } from '../../components/'
import { ReactSVG } from 'react-svg'
import Home from "./Home"
import Flights from "./Flights"
import Fleet from "./Fleet"
import Requests from "./Requests"
import Pilots from "./Pilots"
import Wallet from "./Wallet"
import Settings from "./Settings"
import { ArrowRightOutlined } from "@ant-design/icons";
import { firestore } from "../../firebaseConfig";

const { Header, Content, Footer } = Layout

const Menus = [
    {key: 'home', name: 'Home'},
    {key: 'requests', name: 'Requests'},
    {key: 'flights', name: 'Flights'},
    {key: 'fleets', name: 'Fleet'},
    {key: 'pilots', name: 'Pilots'},
    {key: 'wallet', name: 'Wallet'}
]

function Dashboard (props) {
    const history = useHistory()
    const { currentUser } = useSelector((state) => state.common)
    const { path } = useParams()
    const [sideCollapsed, setSideCollapsed] = useState(false)
    const [modalOpen, setModalOpen] = useState(!currentUser?.profile?.fleetModalSeen)

    let renderPage = ''

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [path])

    const navigateRoute = (route) => {
        history.push(`/dashboard/${route}`)
    }

    let params = {
        history,
        currentUser
    }

    if (currentUser && !currentUser?.profile?.accountCompanyInfoFilled) {
        return <Redirect to="/auth/account-company" />
    }

    switch (path) {
        case 'requests':
            renderPage = <Requests {...params} />
            break
        case 'flights':
            renderPage = <Flights {...params} />
            break
        case 'fleets':
            renderPage = <Fleet {...params} />
            break
        case 'pilots':
            renderPage = <Pilots {...params} />
            break
        case 'wallet':
            renderPage = <Wallet {...params} />
            break
        case 'settings':
            renderPage = <Settings {...params} />
            break
        default:
            renderPage = <Home {...params} />
    }

    let clsName = "dashboard-layout " + path

    if (!currentUser)
        return <Redirect to="/auth/login" />

    const handleModalClose = async () => {
        await firestore.collection('users').doc(currentUser.uid).update({ fleetModalSeen: true });
        setModalOpen(false);
    }
    
    const onModalBtnClick = async () => {
        history.push('/dashboard/fleets');
        await handleModalClose();
    }

    return (
        <Layout className={clsName}>
            <Header><FlyHeader {...params} /></Header>
            <Content>
                <div className={`dashboard-sidebar d-block ${sideCollapsed && 'collapsed'}`}>
                    <ul>
                        {Menus.map((menu, idx) =>
                            <li className={path === menu.key? "active": ""} key={idx} onClick={() => navigateRoute(menu.key)}>
                                <ReactSVG src={`/images/${menu.key}.svg`} className={`side-icon ${menu.key}`} />
                                <h3>{menu.name}</h3>
                            </li>
                        )}
                    </ul>
                    <div className="sidebar-bottom sidebar-bottom-wrapper">
                        <ReactSVG src="/images/backwards.svg" className="collapse-icon" onClick={() => setSideCollapsed(!sideCollapsed)}/>
                        {!sideCollapsed && <div className='copyright'>© {new Date().getFullYear()} flymingo</div>}
                    </div>
                </div>
                { renderPage }
            </Content>
            <Modal
                centered
                open={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                footer={null}
                closeIcon={<ReactSVG src="/images/close.svg" />}
                style={{ borderRadius: 10 }}
                wrapClassName={'dashboard-modal'}
                width={596}
            >
                <p className='dashboard-modal-title'>Add plane photo</p>
                <p className='dashboard-modal-subtitle'>You now have the option to add a photo of your aircraft.</p>
                <div className='fly-btn-dashboard-wrapper'>
                    <Button
                        className="fly-btn"
                        type="primary"
                        onClick={onModalBtnClick}
                    >
                        GO TO FLEET <ArrowRightOutlined />
                    </Button>
                </div>
            </Modal>
        </Layout>
    )
}

export default Dashboard
