import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Spin } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import './index.scss'
import FlightDetails from './FlightDetails'
import { FlyFooter } from '../../../components/'
import { ApiManager } from "../../../service"

function Departures(props) {
    const {history, currentUser, listScrollHeight, setListScrollHeight} = props
    const [flights, setFlights] = useState([])
    const [activeRecord, setActiveRecord] = useState()
    const [loading, setLoading] = useState()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        ApiManager.flyerDepartures({uid: currentUser.uid}).then(res => {
            setFlights(res.data.body.flights)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const handleScroll = (e) => {
        setListScrollHeight(e?.currentTarget?.scrollTop || 0)
    }

    if (activeRecord) {
        setListScrollHeight(0)

        return <FlightDetails
            flight={activeRecord}
            setActiveRecord={setActiveRecord}
            history={history}
            currentUser={currentUser}
            loadDeparturesData={loadData}
        />
    }

    return (
        <div className={`content flight-list${listScrollHeight > 100 ? ' resizePage': ''}`}>
            <div className="client-header">
                <div className="client-header-vectors">
                    <div className="vector-flamingo"><ReactSVG src={'/images/flamingo.svg'} /></div>
                    <div className="vector-cloud top"><ReactSVG src={'/images/cloud.svg'} /></div>
                </div>
                <h2>Departures</h2>
                <div className="d-flex d-space mt-2">
                    <p className="flights-count">{flights.length} Flights</p>
                    {/*<div className="d-flex">
                        <ReactSVG src="/images/filter.svg" />
                        <p className="ml-1">Filters</p>
                    </div>*/}
                </div>
            </div>
            <div className="client-body" onScroll={handleScroll}>
                <div className="list list-mobile">
                    {loading ?
                        <Row className="m-auto">
                            <Col span={24} className="t-center">
                                <Spin />
                            </Col>
                        </Row>
                        : flights.length !== 0 ? flights.map(flight =>
                            <Row className="flight-item flight-item-mobile" onClick={() => setActiveRecord(flight)} key={flight.combinedId} >
                                <Col span={24}>
                                    <div className="flight-item-header">
                                        <div className="d-flex">
                                            <ReactSVG src="/images/flights1.svg" />
                                            <p className="ml-1">{flight.departureTime} • {flight.departureDate}</p>
                                        </div>
                                        <p className="gray">{flight.number}</p>
                                    </div>
                                    <div className="flight-item-body">
                                        <div className="d-flex d-space">
                                            <div>
                                                <h3>{flight.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h3>
                                                <p className="gray">{utils.resources.airports.index[flight?.departureAirport]?.place || ''}</p>
                                            </div>
                                            <ReactSVG src="/images/bird1.svg" className="b-icon" />
                                            <div className="t-right">
                                                <h3>{flight.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h3>
                                                <p className="gray">{utils.resources.airports.index[flight?.arrivalAirport]?.place || ''}</p>
                                            </div>
                                        </div>
                                        <hr className="mh-1" />
                                        <div className="d-flex d-space">
                                            <p>{flight.availableSeats} Seats Available</p>
                                            <div className="d-flex">
                                                <ReactSVG src={`images/${utils.getPlaneType(flight.planeDetails.planeType)}.svg`} className="planetype-icon" />
                                                <p className="ml-1">{flight.planeDetails.planeType}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight-item-footer">
                                        <p className="price">${(+flight.pricePerPerson).toFixed(2)}/person</p>
                                        <p className="time-left">{flight.leftTime} left to book</p>
                                    </div>
                                </Col>
                            </Row>
                        ) : <div className='center-block'>
                            <p className='block-title'>No Departures Yet!</p>
                            <p className='block-text wrap'>You can request a flight or just come back later!</p>
                            <Button
                                type="primary"
                                className="fly-btn centered-small wrapper-2"
                                onClick={() => history.push('/client/request-a-flight')}
                            >
                                Request a Flight
                            </Button>
                        </div>
                    }
                    {!loading && flights.length !== 0 && <div className='empty-block'/>}
                </div>
                {flights.length !== 0 && (
                    <Button type="primary" className="fly-btn w-full btn-position-4" onClick={() => history.push('/client/request-a-flight')}>
                        Request a Flight
                    </Button>
                )}
            </div>
            <FlyFooter active="departures" />
        </div>
    )
}

export default Departures
