import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Form, Input, Button, DatePicker, Checkbox, Select } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import NumberFormat from 'react-number-format'
import { firestore, serverTimestamp, database } from "../../../firebaseConfig"
import FlyTimePicker from '../../../components/FlyTimePicker';
import moment from 'moment';
import prepareNumericalValue from '../../../utils/prepareNumericalValue';
import { resources } from '../../../utils';
import { ApiManager } from '../../../service';

const requiredFields = ['arrivalAirport', 'departureAirport', 'departureDate', 'departureTime',
'availableSeats', 'pricePerPerson', 'pricePerBag']

function AccountCompanyFlights(props) {
    const [form] = Form.useForm()
    const {user, setUser, history, setStep, currentUser} = props
    const [tempVal, setTempVal] = useState({
        recurringRoute: [],
        availableSeats: 0,
        pricePerPerson: 0
    })
    const [selectedDate, setSelectedDate] = useState()
    const [endDate, setEndDate] = useState()
    const [repeatOn, setRepeatOn] = useState([])
    const [loading, setLoading] = useState(false)
    const [departureAirport, setDepartureAirport] = useState('')
    const [arrivalAirport, setArrivalAirport] = useState('')
    const [departureCountry, setDepartureCountry] = useState('')
    const [arrivalCountry, setArrivalCountry] = useState('')

    useEffect(() => {
        if (user.flight)
            setSelectedDate(user.flight.departureDate)
    }, [])

    const onSubmitHandler = (values) => {
        addFlightInfo(values, true)
    }

    const changePage = () => {
        const urlParams = new URLSearchParams(history.location.search)
        urlParams.set('pt', 'fleet')
        history.push(`${history.location.pathname}?${urlParams.toString()}`)
    }

    const onNextHandler = () => {
        form.validateFields().then(values => {
            if (values.errorFields) return;
            addFlightInfo(values, false);
        })
    }

    useEffect(() => {
        if (selectedDate && form.getFieldValue('departureTime')) form.validateFields(['departureTime'])
    }, [selectedDate])

    const addFlightInfo = async (values, addAnother) => {
        setLoading(true)
        // get user info from users collection and check if flights exist.
        const uSnapshot = await firestore.collection('users').doc(currentUser.uid).get()
        if (uSnapshot.exists) { // user exists
            const userData = uSnapshot.data()
            let flights = userData.flights || []
            let cData = Object.assign({}, values)
            cData.uid = currentUser.uid
            cData.createdAt = serverTimestamp
            // replace undefinded values with '' to save data into firestore
            Object.keys(values).forEach(key => {
                if (cData[key] === undefined) cData[key] = ''
            })
            cData.recurringRoute = !!(cData.recurringRoute !== '' && cData.repeatOn &&
                cData.repeatOn.length !== 0 && cData.endsDate && cData.endsDate !== '');
            if (cData.endsDate && cData.endsDate !== '')
                cData.endsDate = cData.endsDate.format('MM/DD/YYYY')
            if (cData.departureTime && cData.departureTime !== '')
                cData.departureTime = cData.departureTime.format('hh:mm a')

            let flightsSnapshot = await database.ref('/counter/flights').once('value')

            if (cData.recurringRoute) {
                cData.recurringId = utils.generateRandomKeys()

                let departureDates = [];

                const firstFlightConvertedDay = moment(cData.departureDate);
                if (!cData.repeatOn.includes(firstFlightConvertedDay.format('dddd'))) {
                    departureDates.push(firstFlightConvertedDay.format('MM/DD/YYYY'))
                }

                for (let i = 0; i <= moment(cData.endsDate).diff(moment(cData.departureDate), 'days'); i++) {
                    const convertedDate = moment(cData.departureDate).add(i, 'days');

                    if (cData.repeatOn.includes(convertedDate.format('dddd'))) {
                        departureDates.push(convertedDate.format('MM/DD/YYYY'))
                    }
                }

                departureDates.map(async (date, index) => {
                    const newCData = { ...cData };
                    newCData.departureDate = date
                    newCData.departureAt = utils.convertToBahamasTimeZone(date, cData.departureTime, false)
                    let nextCounter = flightsSnapshot.val()+index+1
                    await database.ref('counter/flights').set(nextCounter)
                    newCData.number = utils.generateFlightNumber(nextCounter)
                    newCData.public = true;
                    const cSnapshot = await firestore.collection('flights').add(newCData)
                    currentUser.profile.flights.push(cSnapshot.id)
                    utils.pushDataLayer({ event: 'add_flight' })
                    ApiManager.sendSlackAlert({
                        text: `🛫 flight added \n#: ${newCData?.number || ''} \n${resources.airports.index[newCData?.departureAirport]?.code || `${newCData?.departureLocation?.latitude},${newCData?.departureLocation?.longitude}`} - ${resources.airports.index[newCData?.arrivalAirport]?.code || `${newCData?.arrivalLocation?.latitude},${newCData?.arrivalLocation?.longitude}`} \nDeparture date: ${newCData?.departureDate || ''} \nDeparture time: ${newCData?.departureTime || ''}`
                    });
                })
            } else {
                if (cData.departureDate && cData.departureDate !== '')
                    cData.departureDate = cData.departureDate.format('MM/DD/YYYY')
                cData.departureAt = utils.convertToBahamasTimeZone(cData.departureDate, cData.departureTime, false)
                let snapshot = await database.ref('/counter/flights').once('value')
                let nextCounter = snapshot.val() + 1
                database.ref('counter/flights').set(nextCounter)

                cData.number = utils.generateFlightNumber(nextCounter)
                // company info not exists, add new company document to flights collection and add company doc id to user doc.
                cData.public = true;
                const cSnapshot = await firestore.collection('flights').add(cData)
                currentUser.profile.flights.push(cSnapshot.id)
                utils.pushDataLayer({ event: 'add_flight' })
                ApiManager.sendSlackAlert({
                    text: `🛫 flight added \nnumber: ${cData?.number || ''} \n${resources.airports.index[cData?.departureAirport]?.code || `${cData?.departureLocation?.latitude},${cData?.departureLocation?.longitude}`} - ${resources.airports.index[cData?.arrivalAirport]?.code || `${cData?.arrivalLocation?.latitude},${cData?.arrivalLocation?.longitude}`} \nDeparture date: ${cData?.departureDate || ''} \nDeparture time: ${cData?.departureTime || ''}`
                });
            }
            // ApiManager.sendEmail({type: "scheduledFlightConfirmed", id: cSnapshot.id, uid: currentUser.uid,
            //     collection: "flights", verified: "pending", flightNumber: cData.number, reason: ""})
            utils.openNotification('Added a flight successfully!', true)
        }
        else {
            utils.openNotification('User information does not exist', false)
        }
        setUser({...user, flight: undefined })
        setLoading(false)
        if (addAnother) {
            onChangeCheckBox(false);
            form.resetFields();
        } else
            changePage()
    }

    const onChangeCheckBox = (checkedValues) => {
        setTempVal({...tempVal, recurringRoute: checkedValues})
    }
    
    const onChangeAvailableSeats = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, availableSeats: newVal});
        form.setFieldValue('availableSeats', newVal);
    }
    
    const onChangePricePerPerson = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, pricePerPerson: newVal});
        form.setFieldValue('pricePerPerson', newVal);
    }
    
    const onChangePricePerBag = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, pricePerBag: newVal});
        form.setFieldValue('pricePerBag', newVal);
    }

    useEffect(() => {
        form.setFieldsValue({ potentialEarnings: Math.round(tempVal.pricePerPerson * tempVal.availableSeats * 0.75) })
    }, [tempVal])

    let departureAirportList =
        utils.resources.airports[utils.resources.countryContraction[departureCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== arrivalAirport)
    let arrivalAirportList =
        utils.resources.airports[utils.resources.countryContraction[arrivalCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== departureAirport)

    const diffBetweenDays = moment().startOf("day").diff(selectedDate, "days");

    return (
        <div className="content">
            <div className="fly-form fly-form-mob">
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={{...user.flight, recurrenceFrequency: 'Weekly'}}
                >
                    <div className="form-content">
                        <div className="form-header">
                            <h2>Scheduled flights</h2>
                            <p className="fly-info mb-4">Start to recieve requests for empty seats on your already scheduled flights. You will get notified via email each time a request is made for you to review and accept.</p>
                        </div>
                        <div className="form-body">
                            <Row>
                                <Col xl={12} xs={24}  className="pr-2">
                                    <Form.Item label="Departure Country" name='departureCountry' rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Choose departure country"
                                            className="fly-select"
                                            showSearch={true}
                                            options={utils.resources.defaultCountries}
                                            allowClear
                                            onChange={(value) => {
                                                setDepartureCountry(value);
                                                form.setFieldValue('departureAirport', undefined);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24}  className="pl-2">
                                    <Form.Item label="Arrival Country" name='arrivalCountry' rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Choose arrival country"
                                            className="fly-select"
                                            showSearch={true}
                                            options={utils.resources.defaultCountries}
                                            allowClear
                                            onChange={(value) => {
                                                setArrivalCountry(value);
                                                form.setFieldValue('arrivalAirport', undefined);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} xs={24}  className="pr-2">
                                    {departureCountry && (
                                        <Form.Item label="Departure Airport" name='departureAirport' rules={[{ required: true, message: 'This field is required' }]}>
                                            <Select
                                                placeholder="Add departure airport"
                                                className="fly-select"
                                                showSearch={true}
                                                options={departureAirportList}
                                                allowClear
                                                onChange={(value) => setDepartureAirport(value)}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                                <Col xl={12} xs={24}  className="pl-2">
                                    {arrivalCountry && (
                                        <Form.Item label="Arrival Airport" name='arrivalAirport' rules={[{ required: true, message: 'This field is required' }]}>
                                            <Select
                                                placeholder="Add arrival airport"
                                                className="fly-select"
                                                showSearch={true}
                                                options={arrivalAirportList}
                                                allowClear
                                                onChange={(value) => setArrivalAirport(value)}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} xs={24} className="pr-2">
                                    <Form.Item label="Departure Date" name='departureDate'
                                        rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateDate(rule, value, callback, 'Departure date', true)}]}>
                                        <DatePicker
                                            format="MM/DD/YYYY"
                                            placeholder="MM/DD/YYYY"
                                            className="fly-date-picker"
                                            onChange={(date) => setSelectedDate(date)}
                                            defaultPickerValue={utils.getToday()}
                                            disabledDate={utils.disabledDateWithoutToday}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="px-2">
                                    <Form.Item label="Departure Time" name='departureTime'
                                        rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateTime(rule, value, callback, 'Departure time', true, selectedDate)}]}>
                                        <FlyTimePicker
                                            format="hh:mm a"
                                            placeholder="HH:MM PP"
                                            className="fly-date-picker"
                                            showNow={diffBetweenDays < -1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={8} xs={24} className="px-2">
                                    <p className="mt-4 mb-3 additionalLabel">departure time must be > 24 hours from the current time</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} xs={24} className="pr-2">
                                    <Form.Item label="Available Seats" name='availableSeats'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Available seats', true, 1, 19) }]
                                        }>
                                        <Input type="number" className="fly-input" min="1" max="19" placeholder="# of Seats" onChange={onChangeAvailableSeats} />
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="px-2">
                                    <Form.Item label="Price Per Person" name='pricePerPerson'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Price', true, 1) }]
                                        }>
                                        <Input type="number" className="fly-input" min="1" placeholder="Price" prefix="$" onChange={onChangePricePerPerson}/>
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="px-2">
                                    <Form.Item label="Price Per Extra Bag" name='pricePerBag'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Price', true, 0) }]
                                        }>
                                        <Input type="number" className="fly-input" min="0" placeholder="Price" prefix="$" onChange={onChangePricePerBag}/>
                                    </Form.Item>
                                </Col>
                                {   tempVal.availableSeats !== 0 && tempVal.pricePerPerson !== 0 &&
                                    <Col xl={6} xs={24} className="pl-2">
                                        <Form.Item label="Potential Earnings" name='potentialEarnings' >
                                            <NumberFormat className="fly-input potential-earnings" thousandSeparator={true} prefix="$ " readOnly />
                                        </Form.Item>
                                    </Col>}
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Item name='recurringRoute'>
                                        <Checkbox.Group
                                            className="fly-checkbox"
                                            options={[{label: 'Recurring route', value: 'Recurring route'}]}
                                            onChange={onChangeCheckBox}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                tempVal && tempVal.recurringRoute && tempVal.recurringRoute.length > 0 && <Row>
                                    <Col xl={6} xs={24} className="pr-2">
                                        <Form.Item label="Recurrence Frequency" name='recurrenceFrequency'>
                                            <Select
                                                placeholder="Frequency"
                                                className="fly-select"
                                                showSearch={true}
                                                options={utils.resources.frequencies}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={6} xs={24} className="px-2">
                                        <Form.Item
                                            label="Ends Date"
                                            name='endsDate'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateDateCompare(rule, value, callback, 'Ends Date', true, selectedDate)
                                            }]}
                                        >
                                            <DatePicker
                                                format="MM/DD/YYYY"
                                                placeholder="MM/DD/YYYY"
                                                className="fly-date-picker"
                                                disabledDate={utils.disabledDate}
                                                showToday={false}
                                                onChange={(date) => setEndDate(date)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} xs={24} className="pl-2">
                                        <Form.Item
                                            label="Repeat on"
                                            name='repeatOn'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateRepeatOn(rule, value, callback, 'Repeat on', true)
                                            }]}
                                        >
                                            <Checkbox.Group
                                                className="repeat-on-checkbox-grp"
                                                options={utils.resources.repeatOn}
                                                onChange={(value) => setRepeatOn(value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Form.Item shouldUpdate>
                                {() => {
                                    let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                        (user.flight === undefined && !form.isFieldsTouched(requiredFields, true)) ||
                                        (tempVal && tempVal.recurringRoute && tempVal.recurringRoute.length > 0 && (!endDate || repeatOn.length === 0))
                                    return (
                                        <div className={invalid? 'w-full': 'w-right'}>
                                            {
                                                invalid
                                                    ? <Link to="?pt=fleet" className="extra-txt" onClick={changePage}>Add later</Link>
                                                    : <Button type="primary" htmlType="submit" className="fly-btn bordered" loading={loading}>SAVE & ADD ANOTHER</Button>
                                            }
                                            <Button type="primary" className="fly-btn ml-2" onClick={onNextHandler} disabled={currentUser.profile.flights ? false : invalid} loading={loading}>
                                                NEXT<ArrowRightOutlined />
                                            </Button>
                                        </div>
                                    )
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default AccountCompanyFlights
