import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import * as utils from '../../../../utils'
import RequestInfoForm from './RequestInfoForm'
import RequestBidForm from  './RequestBidForm'
import RequestTACForm from  './RequestTACForm'
import RequestReviewForm from  './RequestReviewForm'
import RequestDoneForm from  './RequestDoneForm'
import $ from 'jquery'

function RequestDetailsModal(props) {
    const {isModalVisible, handleOk, handleCancel, step, setStep, currentUser,
            activeRecord, pilots, fleets, fleetsDict, pilotsDict, loadData} = props
    const [bidInfo, setBidInfo] = useState({})

    useEffect(() => {
        if (activeRecord) {
            if (activeRecord.departureTime === 'Sunrise' || activeRecord.departureTime === 'Sunset') {
                setBidInfo({flexible: activeRecord.departureTime})
            } else {
                setBidInfo({
                    departureTime: utils.convertToEuropeLondonTimeZone(activeRecord.departureDate, activeRecord.departureTime)
                })
            }
        }
    }, [activeRecord])

    const onSubmitHandler = (values) => {
        console.log(values)
    }

    useEffect(() => {
        $(".ant-modal-wrap").animate({ scrollTop: "0" })
    }, [step])

    let renderForm = null
    let width = '56.25rem'
    let closable = true

    const params = {
        isModalVisible,
        handleOk,
        handleCancel,
        step,
        setStep,
        bidInfo,
        setBidInfo,
        activeRecord,
        pilots,
        fleets,
        fleetsDict,
        pilotsDict,
        currentUser,
        loadData
    }

    switch (step) {
        case 'bid':
            renderForm = <RequestBidForm {...params} />
            width = '33.75rem'
            break
        case 'tac':
            renderForm = <RequestTACForm {...params} />
            break
        case 'review':
            renderForm = <RequestReviewForm {...params} />
            width = '37.5rem'
            break
        case 'done':
            renderForm = <RequestDoneForm {...params} />
            width = '31.25rem'
            closable = false
            break
        default:
            renderForm = <RequestInfoForm {...params} />
            break
    }

    return (
        <Modal title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={width}
            className="fly-modal content request-details-modal"
            footer={[]}
            destroyOnClose={true}
            maskClosable={true}
            closable={closable}
        >
            <div className="fly-form auth-form">
                {renderForm}
            </div>
        </Modal>
    )
}

export default RequestDetailsModal
