const generateFlightData = (flights) => {
	const HEADER_ROW = [
		{
			value: 'Flight Number',
			fontWeight: 'bold'
		},
		{
			value: 'Departure Date',
			fontWeight: 'bold'
		},
		{
			value: 'Departure Time',
			fontWeight: 'bold'
		},
		{
			value: 'Departure Location (Location)',
			fontWeight: 'bold'
		},
		{
			value: 'Arrival Airport (Location)',
			fontWeight: 'bold'
		},
		{
			value: 'Tail Number',
			fontWeight: 'bold'
		},
		{
			value: 'Pilot',
			fontWeight: 'bold'
		},
		{
			value: 'Passenger Name',
			fontWeight: 'bold'
		},
		{
			value: 'Passenger Weight',
			fontWeight: 'bold'
		},
		{
			value: '# of Bags',
			fontWeight: 'bold'
		},
		{
			value: 'Bag Weight',
			fontWeight: 'bold'
		},
		{
			value: '# of Pets',
			fontWeight: 'bold'
		},
		{
			value: 'Pet Weight',
			fontWeight: 'bold'
		},
	];
	
	const DATA = flights.map((flight) => {
		const flightData = [
			{
				type: String,
				value: flight.number,
			},
			{
				type: String,
				value: flight.departureDate,
			},
			{
				type: String,
				value: flight.departureTime,
			},
			{
				type: String,
				value: flight.departureAirport || `Lat: ${flight.departureLocation?.latitude} Lon: ${flight.departureLocation?.longitude}`,
			},
			{
				type: String,
				value: flight.arrivalAirport || `Lat: ${flight.arrivalLocation?.latitude} Lon: ${flight.arrivalLocation?.longitude}`,
			},
			{
				type: String,
				value: flight.tailNumber,
			},
			{
				type: String,
				value: flight.pilotName,
			},
		];
		
		if (flight.requests && flight.requests.length !== 0) {
			return flight.requests.map(request => {
				return request.passengers.map((passenger, index) => {
					return [
						...flightData,
						{
							type: String,
							value: `${passenger.firstname} ${passenger.lastname}`,
						},
						{
							type: String,
							value: `${passenger.weight || 0} lbs`,
						},
						{
							type: String,
							value: `${passenger.numberOfBags || '0'}`,
						},
						{
							type: String,
							value: `${passenger.weightPerBag || '0'} lbs`,
						},
						{
							type: String,
							value: index === 0 ? `${request.numberOfPets || '0'}` : '0',
						},
						{
							type: String,
							value: index === 0 ? `${request.weightPerPet || 0} lbs` : '0',
						},
					]
				})
			})
		} else {
			return [[flightData]];
		}
	}).flat(2);

	return [
		HEADER_ROW,
		...DATA
	];
}

export default generateFlightData;
