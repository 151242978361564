import React from 'react'
import { Form, Button, Row, Col } from 'antd'

function RequestInfoForm (props) {
    const {setStep} = props
    const [form] = Form.useForm()

    const onSubmitHandler = (values) => {
        setStep('review')
    }

    return (
        <Form
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={onSubmitHandler}
            requiredMark={false}
        >
            <h2 className="t-center">HOW IT WORKS</h2>
            <p className="mt-1">1. Your bid will be available for a passenger to accept 24 hours prior to departure. </p>
            <p className="mt-1">2. Once the bid is accepted, the passenger will have 15 minutes to pay or else the flight is cancelled. </p>
            <p className="mt-1">3. 24 hours prior to departure, a passenger will complete smart check-in for you to review final weight.</p>
            <p className="mt-1">4. If you cancel the flight, the passenger will receive 100% refund.</p>
            <p className="mt-1 ml-2">4.1. If the passenger cancels 24 hours before departure, they will receive no refund.</p>
            <p className="mt-1 ml-2">4.2. If the passenger cancels 48 hours before departure, they will receive a 50% refund.</p>
            <p className="mt-1">5. Once the flight is successfully completed, your wallet will be updated with your funds.</p>
            <Row className="mt-5 d-space">
                <Col><Button type="primary" className="fly-btn green-btn bordered" onClick={() => setStep('bid')}>BACK</Button></Col>
                <Col><Button type="primary" htmlType="submit" className="fly-btn green-btn">I AGREE</Button></Col>
            </Row>
        </Form>
    )
}

export default RequestInfoForm
