import React, { useState } from 'react';
import { Row, Col, Form, Button, Input, Radio, Modal } from 'antd';
import * as utils from '../../../../utils';
import { firestore } from '../../../../firebaseConfig';
import { ApiManager } from '../../../../service';

const { TextArea } = Input;

function CancelFlightModal(props) {
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { flight, history, setActiveRecord, updateData } = props;
	const [loading, setLoading] = useState(false);
	const [otherReasonVisible, setOtherReasonVisible] = useState(false);

	const onSubmitHandler = async (values) => {
		setLoading(true);

		await ApiManager.flyerCancelFlight({ flight, values })
			.then(async () => {
				updateData();

				if (flight?.request?.payment) {
					const paymentSnapshot = await firestore.collection('transactions')
						.doc(flight.request.payment).get();

					if (paymentSnapshot.exists) {
						const payment = paymentSnapshot.data();

						utils.pushDataLayer({ ecommerce: null });
						utils.pushDataLayer({
							event: "refund",
							ecommerce: {
								currency: payment.currency,
								transaction_id: payment.transactionId,
								value: payment.amount,
								items: [
									{
										item_id: flight.number,
										item_name: `${utils.resources.airports.index[flight?.departureAirport]?.code || ''} - ${utils.resources.airports.index[flight?.arrivalAirport]?.code || ''}`,
										item_category: "Request a Flight",
										price: payment.amount,
										quantity: flight.request.passengers.length
									}
								]
							}
						})
					}
				}
			})

		handleOk();
		setActiveRecord();
		setLoading(false);
	}

	const handleCancel = () => {
		setIsModalVisible(false);
	}

	const handleOk = () => {
		setIsModalVisible(false);
	}

	const handleReasonChange = (e) => {
		setOtherReasonVisible(e.target.value === "Other");
	}

	return (
		<>
			<Button type="primary" className="fly-btn w-full mt-2 cancel-btn" onClick={() => setIsModalVisible(true)}>
				Cancel Flight
			</Button>
			<Modal
				title="Cancel Flight"
			  visible={isModalVisible}
			 	onOk={handleOk}
			 	onCancel={handleCancel}
			 	width='23rem'
			 	className="time-picker-modal"
			 	footer={[]}
			 	destroyOnClose={true}
			 	closable={false}
			 	centered
			>
				<Form
					name="time-form"
					id="time-form"
					layout='vertical'
					className=""
					form={form}
					onFinish={onSubmitHandler}
					requiredMark={false}
				>
					<Row>
						<Col span={24}>
							<Form.Item label="Please select a reason" name="reason" >
								<Radio.Group options={utils.resources.cancelFlightReason}
								 className="cancel-request-reason-radio-grp"
								 size='large' optionType="button"
								 onChange={handleReasonChange}
								></Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					{otherReasonVisible && <>
						<Row>
							<Col span={24}>
								<Form.Item label="" name="otherReason" >
									<TextArea rows={3} className="fly-input" placeholder="Enter Other Reason" />
								</Form.Item>
							</Col>
						</Row>
					</>}
					<Row className="mt-2">
						<Col span={24}>
							<Button className="fly-btn w-full" htmlType="submit" loading={loading}>Confirm</Button>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	)
}

export default CancelFlightModal
