import React, { useEffect } from 'react'
import { Row, Col, Button, Statistic, Tooltip } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import * as utils from '../../../../utils'
import CancelFlightModal from './CancelFlightModal'
import { countBags } from '../../../../utils';
import {
    FIXED_STRIPE_COMMISSION,
    FLYMINGO_COMMISSION,
    KANOO_COMMISSION,
    KANOO_PAYMENT_COUNTRY,
    MIN_STRIPE_COMMISSION
} from '../../../../constants';

function FlightInfo(props) {
    const {activeNav, step, setStep, flight, setFlight, setFlightValues, setActiveRecord,
        setActivePassenger, postRequest, loading, currentUser, history, updateData} = props

    const priceWithoutCommissions = flight.totalPrice.toFixed(2);
    const flymingoService = (+priceWithoutCommissions * FLYMINGO_COMMISSION).toFixed(2);

    const IS_KANOO_PAY = flight.pilotDetails.companyCountry === KANOO_PAYMENT_COUNTRY;

    const commissionPercentage = IS_KANOO_PAY ? KANOO_COMMISSION : MIN_STRIPE_COMMISSION;

    const totalTaxes = (
      ((+priceWithoutCommissions + +flymingoService) *
        (commissionPercentage)) / (100 - commissionPercentage)
      + (IS_KANOO_PAY ? 0 : (FIXED_STRIPE_COMMISSION *
      flight.request.splitPaymentData ? flight.request.splitPaymentData.length : 0))
    ).toFixed(2);

    const totalAmountToPay = (+priceWithoutCommissions + +flymingoService + +totalTaxes).toFixed(2);

    useEffect(() => {
        if (!flight.request.payment && activeNav !== 'past' && !flight.expired && IS_KANOO_PAY) {
            window.kanooExternalPayment.createPayWithKanooContainer(
                "#pwk_container",
                process.env.REACT_APP_SECRET_CODE === "Prod" ? "859C3C1D43124E2A8D2DBD4EED9661AA" : "B302ED6833CD485BA39FD7469C4AB2F9",
                process.env.REACT_APP_SECRET_CODE === "Prod" ? 31688 : 1971
            );
            document.getElementById('amountInputTag').value = totalAmountToPay
            document.getElementById('btnPayWithKanoo').innerHTML = "Pay now"
            document.getElementById('callbackUrl').value = `https://us-central1-flymingo---${process.env.REACT_APP_SECRET_CODE === "Prod" ? "prod" : "dev"}.cloudfunctions.net/api/payment?uid=${currentUser.uid}&fid=${flight.id}&rid=${flight.request.id}`
            document.getElementById('redirectUrl').value = `${window.location.origin}/client/flight-requests?ft=upcoming&fd=${flight.id}&rd=${flight.request.id}`
        }
    }, [])

    return (
        <div className="flight-board">
            <div className="form-content">
                <div className="form-body">
                    <p className="section-label">{flight?.departureAirport ? 'Departure Airport' : 'Departure Location'}</p>
                    <Row className="row-wrapper">
                        <Col span={24}>
                            <div className="d-flex-baseline d-space" style={{ alignItems: 'center' }}>
                                <p className="section-desc mr-4">{flight?.departureAirport ? 'Airport' : 'Location'}</p>
                                {flight?.departureAirport
                                    ? (
                                        <p className="t-right">
                                            {flight.departureAirport} <br />
                                            {utils.resources.airports.index[flight?.departureAirport]?.title || ''}
                                        </p>
                                    )
                                    : (
                                        <div>
                                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                                {flight.departureLocation?.latitude}
                                            </p>
                                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                                {flight.departureLocation?.longitude}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="d-flex-baseline d-space mt-1">
                                <p className="section-desc mr-4">Address</p>
                                <a href={`https://www.google.com/maps/@${utils.resources.airports.index[flight?.departureAirport]?.geocode || `${flight.departureLocation?.latitude},${flight.departureLocation?.longitude}`},17z`} target="_blank">Get Directions</a>
                            </div>
                            <div className="d-flex-baseline d-space mt-1">
                                <p className="section-desc mr-4">Departure Time</p>
                                <p className="t-right">{flight.departureTime} • {flight.departureDate}</p>
                            </div>
                        </Col>
                    </Row>

                    <p className="section-label mt-3">Flight Information</p>
                    <Row className="row-wrapper">
                        <Col span={24}>
                            <div className="d-flex d-space">
                                <p className="section-desc">Status</p>
                                 {flight.plane && flight.pilot?
                                    <div className="d-flex">
                                        <ReactSVG src="/images/verified-star.svg" className="mr-1 planetype-icon" />
                                        <p>Verified</p>
                                    </div>
                                    :
                                    <p>unverified</p>
                                }
                             </div>
                             <div className="d-flex d-space mt-1">
                                <p className="section-desc">Pilot</p>
                                <div className="d-flex">
                                    <img alt='' src={flight.pilotDetails.photo || "/images/user-placeholder.png"} className="pilot-profile mr-1" />
                                    <p>{flight.pilotName}</p>
                                </div>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Aircraft</p>
                                <p style={{ textAlign: 'right' }}>{flight.planeDetails.planeMake}</p>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Pilot Type</p>
                                <p className="type-label">Company</p>
                            </div>
                            {/*<div className="d-flex d-space mt-1">
                                <p className="section-desc">Tail Number</p>
                                <p>{flight.planeDetails.tailNumber}</p>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Aircraft Type</p>
                                <p>Basic</p>
                            </div>*/}
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Plane Type</p>
                                <div className="d-flex ">
                                    <ReactSVG src={`images/${utils.getPlaneType(flight.planeDetails.planeType)}.svg`} className="planetype-icon" />
                                    <p className="ml-1">{flight.planeDetails.planeType}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <p className="section-label mt-3">Additional Details</p>
                    <Row className="px-2">
                        <Col span={24}>
                            <div className="d-flex d-space">
                                <p className="section-desc">Status</p>
                                <p className={`type-label ${flight.request.status}`}>
                                    {flight.request.status === 'verified'? 'Pending Payment' : utils.resources.flightStatus[flight.request.status]}
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row-wrapper mt-2">
                        <Col span={24}>
                            <div className="d-flex d-space">
                                <p className="section-desc">Passengers</p>
                                <p>{flight.request.passengersCnt}</p>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Luggage</p>
                                <p>{countBags(flight.request.passengers)} Bags</p>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Pets</p>
                                <p>{flight.request.hasPet? 'Yes': 'No'}</p>
                            </div>
                        </Col>
                    </Row>

                    <p className="section-label mt-4">Total Fees</p>
                    <Row className="row-wrapper mt-1 bg-gray">
                        <Col span={24}>
                            <div className="d-flex d-space">
                                <p className="section-desc">Total</p>
                                <Statistic prefix="$" value={priceWithoutCommissions} precision={2} />
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Service Fee</p>
                                <div className="d-flex">
                                    <Tooltip className="fly-tooltip" title={<p>This is to help run our platform & cover processing fees.</p>} color="#90C4C2" placement="top">
                                        <InfoCircleFilled className="mr-1" style={{ color: "#95999A", fontSize: '12px'}}/>
                                    </Tooltip>
                                    <Statistic prefix="$" value={flymingoService} precision={2} />
                                </div>
                            </div>
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Taxes</p>
                                <Statistic prefix="$" value={totalTaxes} precision={2} />
                            </div>
                            <hr />
                            <div className="d-flex d-space mt-1">
                                <p className="section-desc">Total Amount Paid (BSD)</p>
                                <Statistic prefix="$" value={totalAmountToPay} precision={2} />
                            </div>
                        </Col>
                    </Row>
                    {/*flight.request.status === 'on-time' && <Button type="primary" className={`fly-btn w-full download-ticket mt-3`}>
                        Download Tickets to Wallet
                    </Button>*/}
                    {!flight.request.payment && IS_KANOO_PAY && <Row className="mt-3">
                        <Col span={24}>
                            <div id="pwk_container"></div>
                            <input type="hidden" id="redirectUrl" />
                            <input type="hidden" id="callbackUrl" />
                        </Col>
                    </Row>}
                    {!flight.request.payment && !IS_KANOO_PAY && (
                        <Button
                            type="primary"
                            className="fly-btn w-full mt-2"
                            onClick={() => {
                                window.location.href =
                                `/stripe-payment?uid=${currentUser.uid}&fid=${flight.id}&rid=${flight.request.id}&redirect=/client/flight-requests`;
                            }}
                        >
                            Pay now
                        </Button>
                    )}
                    {!['cancelled', 'on-time', 'checkin', 'manual-checkin-required'].includes(flight.request.status) && activeNav !== 'past' && flight.request.payment && !flight.public && (
                        <Button type="primary" className="fly-btn w-full mt-2 add-btn" onClick={() => setStep('AddPassenger')}>
                            Edit Flight
                        </Button>
                    )}
                    {!['cancelled', 'on-time'].includes(flight.request.status) && activeNav !== 'past' && <CancelFlightModal
                        flight={flight} history={history} setActiveRecord={setActiveRecord} updateData={updateData}
                    />}
                    {!flight.request.payment && <p className="t-center mt-1 t-gray">
                        You will be redirected to another window to complete payment.
                    </p>}
                </div>
            </div>
        </div>
    )
}

export default FlightInfo
