import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Spin, Modal } from 'antd'
import * as utils from '../../../../utils'
import { ReactSVG } from 'react-svg'
import FlightDetails from './FlightDetails'
import { FlyFooter, FlyFlightsNavBar } from '../../../../components/'
import { ApiManager } from "../../../../service"
import { isIOS } from 'react-device-detect';

function Requested(props) {
    const {history, currentUser, activeNav, setActiveNav, setListScrollHeight} = props
    const [flights, setFlights] = useState([])
    const [activeRecord, setActiveRecord] = useState()
    const [loading, setLoading] = useState()

    const showPopup = () => {
        Modal.info({
            icon: null,
            maskClosable: false,
            content:  <div>
                <div
                    style={{
                        position: 'absolute',
                        right: 20,
                        top: 20
                    }}
                    onClick={() => Modal.destroyAll()}
                >
                    <ReactSVG src="/images/close.svg" />
                </div>
                <div style={{
                    fontFamily: "WorkSans-Bold",
                    fontSize: "1.25rem",
                    lineHeight: "1.5rem",
                    textAlign: "center"
                }}>
                    Download app
                </div>
                <div style={{
                    fontFamily: "WorkSans-Regular",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    textAlign: "center",
                    marginTop: 5,
                    marginBottom: 20
                }}>
                    You may download the Flymingo App to have a better experience
                </div>
                <div style={{
                    textAlign: "center",
                    padding: 15,
                    fontSize: "1rem",
                    border: '1px solid #DADBDA',
                    color: '#2F7BFA'
                }} onClick={() => {
                    window.location.href = isIOS
                        ? 'https://apps.apple.com/us/app/flymingo/id6468070309?itsct=apps_box_link&itscg=30200'
                        : 'https://play.google.com/store/apps/details?id=com.flymingo.flymingo';
                    Modal.destroyAll();
                }}>
                    Show Flymingo app page in store
                </div>
            </div>
        })
    }

    useEffect(() => {
        let params = new URLSearchParams(history.location.search);
        let isShowPopup = params.get('popup');
        if (isShowPopup === 'show') {
            showPopup();
            params.delete('popup');
            window.history.replaceState(null, '', `${history.location.pathname}?${params.toString()}`);
        }
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        ApiManager.flyerRequests({uid: currentUser.uid}).then(res => {
            setFlights(res.data.body.requests)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const onNavSwitchHandler = (nav) => {
        setActiveNav(nav)
    }

    const handleScroll = (e) => {
        setListScrollHeight(e?.currentTarget?.scrollTop || 0)
    }

    if (activeRecord) {
        setListScrollHeight(0);

        return <FlightDetails
            flight={activeRecord}
            setActiveRecord={setActiveRecord}
            history={history}
            currentUser={currentUser}
            setActiveNav={setActiveNav}
        />
    }

    return (
        <>
            <div className="client-header">
                <div className="client-header-vectors">
                    <div className="vector-flamingo"><ReactSVG src={'/images/flamingo.svg'} /></div>
                    <div className="vector-cloud top"><ReactSVG src={'/images/cloud.svg'} /></div>
                </div>
                <h2>My Flights</h2>
                <FlyFlightsNavBar active={activeNav} onNavSwitchHandler={onNavSwitchHandler}/>
                <div className="mt-4">
                    <p className="flights-count">{flights.length} Flights</p>
                </div>
            </div>
            <div className="client-body list list-mobile" onScroll={handleScroll}>
                {loading ?
                    <Row className="m-auto">
                        <Col span={24} className="t-center">
                            <Spin />
                        </Col>
                    </Row>
                    : flights.length > 0 ? flights.map(flight =>
                        <Row
                            className="flight-item flight-item-mobile"
                            onClick={() => {
                                if (flight.status === 'info-needed') {
                                    window.location.href = `/client/request-a-flight?rid=${flight.id}`
                                } else setActiveRecord(flight)
                            }}
                            key={flight.id}
                        >
                            <Col span={24}>
                                <div className="flight-item-header">
                                    <div className="d-flex">
                                        <ReactSVG src="/images/flights1.svg" />
                                        <p className="ml-1">{flight.departureTime} • {flight.departureDate}</p>
                                    </div>
                                    <p className="gray">{flight.number}</p>
                                </div>
                                <div className="flight-item-body">
                                    <div className="d-flex d-space">
                                        <div style={{ width: '50%' }}>
                                            <h3>{flight.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h3>
                                            {flight?.departureAirport
                                                ? <p className="gray">{utils.resources.airports.index[flight?.departureAirport]?.place || ''}</p>
                                                : (
                                                    <div>
                                                        <p style={{ fontSize: '12px' }}>
                                                            {flight.departureLocation?.latitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                                        </p>
                                                        <p style={{ fontSize: '12px' }}>
                                                            {flight.departureLocation?.longitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <ReactSVG src="/images/bird1.svg" className="b-icon" />
                                        <div className="t-right" style={{ width: '50%' }}>
                                            <h3>{flight.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h3>
                                            {flight?.arrivalAirport
                                                ? <p className="gray">{utils.resources.airports.index[flight?.arrivalAirport]?.place || ''}</p>
                                                : (
                                                    <div>
                                                        <p style={{ fontSize: '12px' }}>
                                                            {flight.arrivalLocation?.latitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                                        </p>
                                                        <p style={{ fontSize: '12px' }}>
                                                            {flight.arrivalLocation?.longitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <hr className="mh-1" />
                                    <div className="d-flex d-space">
                                        <p>{flight.passengers ? Object.keys(flight.passengers).length : 0} Passengers</p>
                                        <div className="d-flex">
                                            <ReactSVG src={`images/${utils.getPlaneType(flight.planeType)}.svg`} className="planetype-icon" />
                                            <p className="ml-1">{flight.planeType}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flight-item-footer">
                                    <p className="time-left">Ends in {flight.leftTime}</p>
                                    <p className={`price ${flight.status}`}>{utils.resources.requestStatus[flight.status]}</p>
                                </div>
                            </Col>
                        </Row>
                    ) : <div className='center-block'>
                        <p className='block-title'>No Pending Flights Yet</p>
                        <Button
                            type="primary"
                            className="fly-btn centered-small wrapper"
                            onClick={() => history.push('/client/request-a-flight')}
                        >
                            Request a Flight
                        </Button>
                        <p className='block-text'>or visit <span onClick={() => history.push('/client/departures')}>departures</span>.</p>
                    </div>
                }
            </div>
            <FlyFooter active="flights" />
        </>
    )
}

export default Requested
