import React, { useState } from 'react'
import { Form, Button, Collapse } from 'antd'
import { useDispatch } from 'react-redux'
import { CaretRightOutlined } from '@ant-design/icons'
import UploadAvatarModal from "./UploadAvatarModal"
import CompanyInformationPanel from "./CompanyInformationPanel"
import CredentialsPanel from "./CredentialsPanel"
import ContactInformationPanel from "./ContactInformationPanel"
import FixedBasedOperationsPanel from "./FixedBasedOperationsPanel"
import PaymentMethodPanel from "./PaymentMethodPanel"
import FlyActions from '../../../redux/common'
import * as utils from '../../../utils'
import { auth, firestore } from "../../../firebaseConfig"
import './index.scss'
import ApiManager from '../../../service';
import { STRIPE_PAYMENT_COUNTRY } from '../../../constants';

const { Panel } = Collapse

function Settings (props) {
    const {history, currentUser} = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [avatar, setAvatar] = useState(currentUser.photoURL)
    const [uploadedFiles, setUploadedFiles] = useState({files: [], uploading: false})
    const [loading, setLoading] = useState(false)
    const defaultActiveKey = history.location?.state?.isCompanyInformationOpen ? 'companyInformation' : '';

    const onSubmitHandler = async (values) => {
        if (Object.keys(values).length === 0) return;
        setLoading(true)
        let uData = Object.assign({}, values)
        if (uData.licenseExpirationDate && uData.licenseExpirationDate !== '')
            uData.licenseExpirationDate = uData.licenseExpirationDate.format('MM/DD/YYYY')
        uData.businessLicense = uploadedFiles.files || []
        if (uData.businessLicense.length === 0) uData.licenseExpirationDate = ''
        if (currentUser.profile.businessLicense || currentUser.profile.licenseExpirationDate) {
            const sSnapshot = await firestore.collection('schedules')
                .where('uid', '==', currentUser.uid)
                .where('licenseType', '==', 'businessLicense').get();
            if (!sSnapshot.empty) {
                sSnapshot.forEach(doc => {
                    firestore.collection('schedules').doc(doc.id).delete();
                })
            }
        }
        if (uData.businessLicense.length !== 0 && uData.licenseExpirationDate) {
            ApiManager.scheduleLicenseExpiration({ uid: currentUser.uid, type: 'businessLicense', date: uData.licenseExpirationDate });
        }
        if (values?.payment && Object.keys(values.payment).length !== 0 && !values.payment.type) {
            uData.payment.type = currentUser.profile.country === STRIPE_PAYMENT_COUNTRY ? 'Stripe' : 'Kanoo Pay'
        }
        await firestore.collection('users').doc(currentUser.uid).update(uData)
            .then(() => uploadedFiles.files.length !== 0 && utils.pushDataLayer({ event: 'uploaded_document' }))
        if (avatar) {
            await currentUser.updateProfile({ photoURL: avatar })
        }
        if (values.email && values.email !== currentUser.email) {
            // This is for default firebase email change
            // auth.signInWithEmailAndPassword(currentUser.email, currentUser.profile.password)
            // .then((res) => {res.user.updateEmail(values.email)})
            // .catch(error => {})

            await ApiManager.changeEmail({ uid: currentUser.uid, email: values.email, oldEmail: currentUser.email })
                .then(async () => {
                    await auth.signInWithEmailAndPassword(values.email, currentUser.profile.password)
                })
            // await user.sendEmailVerification({url: `${utils.domain}/auth/login`})
        } else {
            dispatch(FlyActions.onLoadUser(currentUser))
        }
        if (values.password && values.password !== currentUser.profile.password) {
            await auth.currentUser.updatePassword(values.password)
                .catch(() => {
                    auth.signInWithEmailAndPassword(currentUser.profile.email, currentUser.profile.password)
                        .then(() =>  {
                            auth.currentUser.updatePassword(values.password);
                        })
                })
        }
        ApiManager.sendEmail({
            type: "businessRequest",
            id: currentUser.uid,
            uid: currentUser.uid,
            collection: "users",
            verified: "pending",
            reason: ""
        })
        utils.openNotification('Saved successfully!')
        setLoading(false)
    }

    return (
        <div className="content fly-board settings-board">
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={currentUser.profile}
                >
                    <div className="form-content">
                        <div className="form-header d-flex">
                            <UploadAvatarModal currentUser={currentUser}
                                avatar={avatar}
                                setAvatar={setAvatar}
                            />
                            <h2 className="mb-0 ml-4">{currentUser.profile.companyName}</h2>
                        </div>
                        <hr className="m-auto" />
                        <div className="form-body">
                            <Collapse
                                bordered={false}
                                defaultActiveKey={defaultActiveKey}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : -90} />}
                                className="settings-collapse"
                            >
                                <Panel header={<h3>Company Information</h3>} key="companyInformation">
                                    <CompanyInformationPanel
                                        form={form}
                                        currentUser={currentUser}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                    />
                                </Panel>
                                <Panel header={<h3>Credentials</h3>} key="credentials">
                                    <CredentialsPanel currentUser={currentUser}/>
                                </Panel>
                                <Panel header={<h3>Company Address</h3>} key="contactInformation">
                                    <ContactInformationPanel form={form}/>
                                </Panel>
                                <Panel header={<h3>Fixed Based Operations</h3>} key="fixedBasedOperations">
                                    <FixedBasedOperationsPanel mainform={form} profile={currentUser.profile} />
                                </Panel>
                                <Panel header={<h3>Payment Method</h3>} key="paymentMethod">
                                    <PaymentMethodPanel profile={currentUser.profile} />
                                </Panel>
                                {/*<Panel header={<h3>Team</h3>} key="team">
                                        <TeamPanel />
                                    </Panel>
                                    <Panel header={<h3>Beneficiary Information</h3>} key="beneficiaryInformation">
                                        <BeneficiaryInformationPanel />
                                    </Panel>
                                    <Panel header={<h3>Notifications</h3>} key="notifications">
                                        <NotificationPanel />
                                    </Panel>
                                */}
                            </Collapse>
                        </div>
                    </div>
                    <Form.Item className="mt-5 t-right">
                        <Button type="primary" htmlType="submit" className="fly-btn" loading={loading}>SAVE</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Settings
