import { COMMON } from '../constants'
import { firestore, getUserDocument } from '../../firebaseConfig'
import * as utils from '../../utils'

export const onLoadUser = (user) => (dispatch) => {
    if (user)
        getUserDocument(user.uid).then(async res => {
            user.profile = res
            let currentTime = utils.getToday()
            if (user.profile.licenseExpirationDate && user.profile.licenseExpirationDate !== '')
                user.profile.licenseExpirationDate = utils.getDate(user.profile.licenseExpirationDate)
            user.profile.pilots = user.profile.pilots || []
            user.profile.fleets = user.profile.fleets || []
            const fSnapshot = await firestore.collection('flights').where('uid', '==', user.uid).get()
            let upcomingFlights = []
            let flights = []
            if (!fSnapshot.empty) {
                fSnapshot.forEach(doc => {
                    let fItem = doc.data()
                    flights.push(doc.id)
                    if (fItem.departureAt.toDate() > currentTime && (!fItem.cancelled))
                        upcomingFlights.push(doc.id)
                })
            }
            user.profile.upcomingFlights = upcomingFlights || []
            user.profile.flights = flights || []
            user.profile.requests = 0
            dispatch({type: 'USER_LOADED', user})
        })
    else {
        dispatch({type: 'USER_LOADED', user })
    }
}
