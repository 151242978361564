import React, { useState, useEffect } from 'react'
import { Form, Button, Space, Table, Modal, Tabs } from 'antd'
import * as utils from '../../../utils'
import { firestore, serverTimestamp } from "../../../firebaseConfig"
import NumberFormat from 'react-number-format'
import { ReactSVG } from 'react-svg'
import { ApiManager } from "../../../service"
import PassengerInfoModal from './PassengerInfoModal'
import ReviewModal from './ReviewModal';
import { sendExpoNotificationToUser } from '../../../utils';

function Requests(props) {
    const [form] = Form.useForm()
    const {activeFlight, setActiveFlight, flightColumns, loadData, currentUser, fleets} = props
    const [requests, setRequests] = useState([])
    const [checkinRequests, setCheckinRequests] = useState([])
    const [activeRecord, setActiveRecord] = useState()
    const [bookedSeats, setBookedSeats] = useState(0)
    const [loading, setLoading] = useState()
    const [isPassengerInfoVisible, setIsPassengerInfoVisible] = useState(false)
    const [isReviewVisible, setIsReviewVisible] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setRequests(activeFlight.requests)
        setCheckinRequests(activeFlight.checkinRequests)
        setBookedSeats(activeFlight.bookedSeats||0)
    }, [activeFlight])

    const onSubmitHandler = (values) => {
        console.log(values)
        utils.openNotification('Saved successfully!', true)
        setActiveFlight(null)
    }

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 5,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }

    const requestList = requests.map(request => {
        let bags = 0
        let totalWeight = 0
        let requestedSeats = Object.keys(request.passengers).length
        Object.keys(request.passengers).forEach(key => {
            let passenger = request.passengers[key]
            if (passenger.numberOfBags && passenger.weightPerBag) {
                let bagCnt = parseInt(passenger.numberOfBags)
                bags += bagCnt
                let weights = passenger.weightPerBag.split('-')
                let midWeight = (parseInt(weights[0]) + parseInt(weights[1]))/2
                totalWeight += parseInt(passenger.numberOfBags) * midWeight
            }
            totalWeight += parseInt(passenger.weight)
        })
        if (request.hasPet && request.numberOfPets && request.weightPerPet) {
            const weights = request.weightPerPet.split('-');
            const midWeight = (parseInt(weights[0]) + parseInt(weights[1]))/2
            totalWeight += parseInt(request.numberOfPets) * midWeight
        }

        let bagsForPayCount = 0;
        request.passengers && Object.keys(request.passengers).map(pkey => {
            let passenger = request.passengers[pkey];
            const passengerBagCnt = passenger.numberOfBags || 0;
            bagsForPayCount += passengerBagCnt > 1 ? passengerBagCnt - 1 : 0;
        })

        return {
            id: request.id,
            request: request.number,
            requestedSeats: requestedSeats,
            numberOfBags: bags,
            numberOfPets: request.numberOfPets || 0,
            weightPerPet: request.weightPerPet || 0,
            totalWeight: totalWeight,
            price: activeFlight.pricePerPerson,
            total: +(((activeFlight.pricePerPerson) *
              (activeFlight.public
                ? requestedSeats
                : fleets.find(fleet => fleet.id === activeFlight.plane).seatCapacity) +
              (activeFlight.pricePerBag || 0) * bagsForPayCount).toFixed(2)),
            status: request.status,
            passengers: request.passengers,
            uid: request.uid,
            rid: request.rid
        }
    })

    let checkinList = []
    for(let cid=0;cid<checkinRequests.length;cid++){
        let request = checkinRequests[cid]
        for(let pid=0;pid<request.passengers.length;pid++) {
            let passenger = request.passengers[pid]
            let totalWeight = parseInt(passenger.weight)
            if (passenger.numberOfBags && passenger.weightPerBag) {
                let weights = passenger.weightPerBag.split('-')
                let midWeight = (parseInt(weights[0]) + parseInt(weights[1]))/2
                totalWeight += parseInt(passenger.numberOfBags) * midWeight
            }
            if (pid === 0 && request.hasPet && request.numberOfPets && request.weightPerPet) {
                const weights = request.weightPerPet.split('-');
                const midWeight = (parseInt(weights[0]) + parseInt(weights[1]))/2
                totalWeight += parseInt(request.numberOfPets) * midWeight
            }
            let changes = ''
            if (passenger.changes) {
                if (passenger.changes.length > 0)
                    changes = `Changes To ${passenger.changes.join(', ')}`
                else
                    changes = "No Change"
            }
            else
                changes = null

            let status = passenger.status || "awaiting"
            if (changes === '')
                status = 'verified'

            checkinList.push({
                id: `${request.id}-${pid}`,
                request: request,
                psgId: pid,
                passenger: `${passenger.firstname} ${passenger.lastname}`,
                numberOfBags: passenger.numberOfBags || 0,
                totalWeight: totalWeight,
                status: status,
                changes: changes,
                numberOfPets: pid === 0 ? request.numberOfPets: 0
            })
        }
    }

    const onHandleRequest = async (record, status) => {
        setLoading(true)
        await firestore.collection('flights').doc(activeFlight.id).collection('requests').doc(record.id).update({
            status,
            createdAt: serverTimestamp
        })
        
        if (record?.rid) {
            await firestore.collection('requests').doc(record.rid).update({
                status,
                fid: activeFlight.id,
                rid: record.id
            })
        }

        const rSnapshot = await firestore.collection('flights').doc(activeFlight.id).collection('requests').doc(record.id).get()

        if (rSnapshot.exists) {
            const rData = rSnapshot.data();

            let bookedSeatsTemp = bookedSeats || 0;

            if ((record.status === 'verified' || record.status === 'approved') && status === 'denied' && bookedSeatsTemp > 0) {
                bookedSeatsTemp = bookedSeatsTemp - rData.passengers.length
            }
            if (status === 'approved') {
                bookedSeatsTemp = bookedSeatsTemp + rData.passengers.length
                ApiManager.sendEmail({type: 'approvedRequest', uid: record.uid});

                const title = 'Your Request To Fly Was Accepted';
                const content = 'Your Request To Fly Was Accepted confirm your seat by paying before the request expires.';

                ApiManager.addNotification({
                    uid: record.uid,
                    type: 'acceptedBid',
                    title,
                    content,
                    icon: 'smile',
                    fid: activeFlight.id,
                    showInApp: true
                })
                sendExpoNotificationToUser({
                    uid: record.uid, title, content, data: { type: 'acceptedBid', fid: activeFlight.id }
                });
                ApiManager.sendSlackAlert({
                    text: `🛫 request to fly was accepted \n#: ${activeFlight?.number || ''}`
                })
            } else {
                ApiManager.sendEmail({type: 'flightRequestCancelled', uid: record.uid, flightNumber: activeFlight.number})

                const title = 'Your Flight Request Declined';
                const content = 'Your Flight Request Declined please send another request or check out our upcoming departures.';

                ApiManager.addNotification({
                    uid: record.uid,
                    type: 'requestExpired',
                    title,
                    content,
                    icon: 'cancel',
                    showInApp: true
                })
                sendExpoNotificationToUser({
                    uid: record.uid, title, content, data: { type: 'requestExpired' }
                });
                ApiManager.sendSlackAlert({
                    text: `❌ request to fly was declined \n#: ${activeFlight?.number || ''}`
                })
            }

            await firestore.collection('flights').doc(activeFlight.id).update({
                bookedSeats: bookedSeatsTemp
            })
            setBookedSeats(bookedSeatsTemp)
            setActiveFlight({...activeFlight, leftSeats: activeFlight.availableSeats - bookedSeatsTemp, bookedSeats: bookedSeatsTemp})

            const requestSnapshot = await firestore.collection('flights').doc(activeFlight.id).collection('requests').get()
            if (!requestSnapshot.empty) {
                let data = []
                requestSnapshot.forEach(doc => {
                    let rItem = doc.data()
                    rItem.id = doc.id
                    // if (rItem.status !== 'denied')
                    data.push(rItem)
                })
                setRequests(data)
            }
        }

        setLoading(false)
    }

    const onHandleCheckin = async (record, status) => {
        setLoading(true)
        let passengers = record.request.passengers.map((passenger, idx) => {
            if (idx === record.psgId) {
                passenger.status = status
            }
            return passenger
        })
        await firestore.collection('flights').doc(activeFlight.id).collection('requests').doc(record.request.id).update({
            passengers: passengers,
            status: status === 'verified' ? 'on-time' : 'manual-checkin-required'
        })
        setLoading(false)
    }

    const approveCheckin = async (record) => {
        setLoading(true)
        await firestore.collection('flights').doc(activeFlight.id).collection('requests').doc(record.id).update({
            status: 'on-time'
        })
        ApiManager.sendEmail({type: 'approvedCheckIn', uid: record.uid,
            fid: activeFlight.id, rid: record.id
        })
        const requestSnapshot = await firestore.collection('flights').doc(activeFlight.id).collection('requests').get()
        if (!requestSnapshot.empty) {
            let data = []
            requestSnapshot.forEach(doc => {
                let rItem = doc.data()
                rItem.id = doc.id
                // if (rItem.status !== 'denied')
                data.push(rItem)
            })
            setRequests(data)
        }
        setLoading(false)
    }

    const handleCancel = () => {
        setActiveRecord()
        setIsPassengerInfoVisible(false)
        setIsReviewVisible(false)
    }

    const handleOk = () => {
        setActiveRecord()
        setIsPassengerInfoVisible(false)
        setIsReviewVisible(false)
    }

    const openReviewModal = (record, isPendingReview) => {
        if (!isPendingReview) return;
        setActiveRecord(record);
        setIsReviewVisible(true);
    }

    const openPassengerInfo = (record) => {
        setActiveRecord(record);
        setIsPassengerInfoVisible(true);
    }

    const requestColumns = [
        {
            title: 'Request #',
            dataIndex: 'request',
            key: 'request',
            render: (text, record) => <Space>
                <a className="extra-txt" onClick={() => openPassengerInfo(record)}>{text}</a>
            </Space>,
            width: '12%'
        },  {
            title: '# of Seats',
            dataIndex: 'requestedSeats',
            key: 'requestedSeats',
            render: (text, record) => <Space>
                <ReactSVG src="/images/seat.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        },  {
            title: '# of Bags',
            dataIndex: 'numberOfBags',
            key: 'numberOfBags',
            render: (text, record) => <Space>
                <p>{text}</p>
            </Space>,
        },  {
            title: '# of Pets',
            dataIndex: 'numberOfPets',
            key: 'numberOfPets',
            render: (text, record) => <Space>
                <p>{text}</p>
            </Space>,
        },  {
            title: 'Total Weight (lbs)',
            dataIndex: 'totalWeight',
            key: 'totalWeight',
            render: (text, record) => <Space>
                <ReactSVG src="/images/weight.svg" className="label-icon" />
                <NumberFormat className="fly-text" thousandSeparator={true} value={text} readOnly/>
            </Space>,
        },  {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => <Space>
                <ReactSVG src="/images/price.svg" className="label-icon" />
                <p className="mr-1">${text}</p>
            </Space>,
        },  {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => <Space>
                <ReactSVG src="/images/price.svg" className="label-icon" />
                <p className="mr-1">${text}</p>
            </Space>,
        },  {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div className="d-flex ctrl-btns">
                <Space className={`status-btn ${text}`}>
                    {text === 'verified' ? 'Pending Payment' : utils.resources.flightStatus[text]}
                </Space>
            </div>
        },  {
            title: '',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const isExpired = activeFlight.rTitle === "Expired";

                return (
                    <div className="d-flex ctrl-btns">
                        {(text === 'pending' || text === 'approved') && !isExpired &&<>
                            {(+record.requestedSeats <= +activeFlight.leftSeats) && text !== 'approved' && (
                                <Space className="mr-2" onClick={() => onHandleRequest(record, 'approved')}><ReactSVG src="/images/approve.svg" />Approve</Space>
                            )}
                            <Space onClick={() => onHandleRequest(record, 'denied')}><ReactSVG src="/images/refuse.svg" />Refuse</Space>
                        </>}
                        {text === 'verified' && !isExpired &&<>
                            <Space className="ml-2" onClick={() => onHandleRequest(record, 'denied')}><ReactSVG src="/images/refuse.svg" />Refuse</Space>
                        </>}
                        {text === 'manual-checkin-required' && !isExpired &&<>
                            <Space onClick={() => approveCheckin(record)}><ReactSVG src="/images/approve.svg" />Approve</Space>
                        </>}
                    </div>
                )
            }
        }
    ]

    const bagsPhotos = activeRecord?.request?.passengers
        ? activeRecord.request.passengers[activeRecord?.psgId]?.bagsPhotos || []
        : [];
    const petsPhotos = (activeRecord?.psgId === 0 && activeRecord?.request?.petsPhotos)
        ? activeRecord.request.petsPhotos || []
        : [];
    
    const items = [
        {
            key: '1',
            label: 'All',
            children: <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '24px',
                    height: '500px',
                    overflowY: 'auto'
                }}
            >
                {[...bagsPhotos, ...petsPhotos].map(url => {
                    return <a href={url} target='_blank'>
                        <img
                            style={{
                                width: '244px',
                                height: '244px',
                                borderRadius: '23px',
                                border: '2px solid #90C4C2',
                                objectFit: 'cover'
                            }}
                            src={url}
                            alt=''
                        />
                    </a>
                })}
            </div>
        },
        {
            key: '2',
            label: `Bags (${activeRecord?.numberOfBags || 0})`,
            children: <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '24px',
                    height: '500px',
                    overflowY: 'auto'
                }}
            >
                {bagsPhotos.map(url => {
                    return <a href={url} target='_blank'>
                        <img
                            style={{
                                width: '244px',
                                height: '244px',
                                borderRadius: '23px',
                                border: '2px solid #90C4C2',
                                objectFit: 'cover'
                            }}
                            src={url}
                            alt=''
                        />
                    </a>
                })}
            </div>
        },
        {
            key: '3',
            label: `Pets (${activeRecord?.numberOfPets || 0})`,
            children: <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '24px',
                    height: '500px',
                    overflowY: 'auto'
                }}
            >
                {petsPhotos.map(url => {
                    return <a href={url} target='_blank'>
                        <img
                            style={{
                                width: '244px',
                                height: '244px',
                                borderRadius: '23px',
                                border: '2px solid #90C4C2',
                                objectFit: 'cover'
                            }}
                            src={url}
                            alt=''
                        />
                    </a>
                })}
            </div>,
        },
    ];
    
    
    const checkinColumns = [
        {
            title: 'Passenger',
            dataIndex: 'passenger',
            key: 'passenger',
            render: (text, record) => <Space>
                {text}
            </Space>,
            width: '12%'
        },  {
            title: '# of Bags',
            dataIndex: 'numberOfBags',
            key: 'numberOfBags',
            render: (text, record) => <Space>
                <p>{text}</p>
            </Space>,
        },  {
            title: '# of Pets',
            dataIndex: 'numberOfPets',
            key: 'numberOfPets',
            render: (text, record) => <Space>
                <p>{text || 0}</p>
            </Space>,
        },  {
            title: 'Bags & pets preview',
            dataIndex: 'preview',
            key: 'preview',
            render: (text, record) => (
                <>
                    <Space>
                        {((!!record.numberOfBags || !!record.numberOfPets) && record.status !== 'awaiting') && (
                            <div
                                style={{
                                    padding: '8px',
                                    borderRadius: '5px',
                                    border: '1px solid #90C4C2',
                                    color: '#458796',
                                    fontSize: '16px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setModalOpen(true);
                                    setActiveRecord(record);
                                }}
                            >
                                View Bags & Pets
                            </div>
                        )}
                    </Space>
                    <Modal
                        centered
                        open={modalOpen}
                        onOk={() => {
                            setModalOpen(false);
                            setActiveRecord();
                        }}
                        onCancel={() => {
                            setModalOpen(false);
                            setActiveRecord();
                        }}
                        footer={null}
                        className="fly-modal content request-details-modal"
                        width={'920px'}
                        destroyOnClose
                    >
                        <p
                            style={{
                                color: '#212121',
                                fontFamily: "WorkSans-Bold",
                                fontSize: '30px',
                                marginBottom: '24px'
                            }}
                        >
                            Bags & Pets details
                        </p>
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                        />
                    </Modal>
                </>
            ),
        },  {
            title: 'Total Weight (lbs)',
            dataIndex: 'totalWeight',
            key: 'totalWeight',
            render: (text, record) => <Space>
                <ReactSVG src="/images/weight.svg" className="label-icon" />
                <NumberFormat className="fly-text" thousandSeparator={true} value={text} readOnly/>
            </Space>,
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div className="d-flex ctrl-btns" onClick={() => openReviewModal(record, text === 'pending')}>
                <Space className={`status-btn ${text}`}>
                    {utils.resources.checkinStatus[text]}
                </Space>
            </div>
        },  {
            title: 'Description',
            dataIndex: 'changes',
            key: 'changes',
            render: (text, record) => <Space>{text}</Space>
        },  {
            title: '',
            dataIndex: 'status',
            key: 'action',
            render: (text, record) => <div className="d-flex ctrl-btns">
                {text === 'pending' && <>
                    <Space onClick={() => onHandleCheckin(record, 'verified')}><ReactSVG src="/images/approve.svg" />Approve</Space>
                    <Space onClick={() => onHandleCheckin(record, 'denied')} className="ml-2"><ReactSVG src="/images/refuse.svg" />Refuse</Space>
                </>}
                {/* text === 'verified' &&<>
                    <Space onClick={() => onHandleCheckin(record, 'denied')}><ReactSVG src="/images/refuse.svg" />Refuse</Space>
                </> */}
            </div>
        }
    ]

    const isCheckin = activeFlight.rTitle === "Check-In"

    return (
        <div className="content flights-board fly-board requests-view">
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                >
                    <div className="form-content">
                        <div className="form-header d-flex d-space mb-4">
                            <Space>
                                <ReactSVG src="/images/back.svg" className="back-icon" onClick={() => setActiveFlight(null)}/>
                                {isCheckin
                                    ? <h2 className="mb-0">{checkinList.length} Check-In{checkinList.length > 1 && 's'}</h2>
                                    : <h2 className="mb-0">{requests.length} Request{requests.length > 1 && 's'}</h2>
                                }
                            </Space>
                            <Button type="primary" className="fly-btn"></Button>
                            {isPassengerInfoVisible &&
                                <PassengerInfoModal
                                    isModalVisible={isPassengerInfoVisible}
                                    handleCancel={handleCancel}
                                    handleOk={handleOk}
                                    activeRecord={activeRecord}
                                    currentUser={currentUser}
                                />
                            }
                            {isReviewVisible &&
                                <ReviewModal
                                    isModalVisible={isReviewVisible}
                                    handleCancel={handleCancel}
                                    handleOk={handleOk}
                                    activeRecord={activeRecord}
                                    currentUser={currentUser}
                                />
                            }
                        </div>
                        <div className="form-body">
                            <Table columns={flightColumns} dataSource={[activeFlight]}
                                pagination={false} />
                            <Table columns={isCheckin? checkinColumns: requestColumns}
                                dataSource={isCheckin? checkinList: requestList}
                                pagination={pagination} loading={loading}/>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Requests

