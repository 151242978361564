import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import { firestore } from '../../../firebaseConfig'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import AddPassenger from './AddPassenger'
import TermsAndConditions from './TermsAndConditions'
import Review from './Review'
import Request from './Request'
import { ApiManager } from "../../../service"

function RequestFlight(props) {
    const {history, currentUser} = props
    const [step, setStep] = useState(1)
    const [flight, setFlight] = useState({})
    const [activePassenger, setActivePassenger] = useState()
    const [companies, setCompanies] = useState([])
    const urlParams = new URLSearchParams(history.location.search)
    const requestId = urlParams.get('rid')

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const uSnapshot = await firestore.collection('users').where('role', '!=', 'Client').get()
        if (!uSnapshot.empty)
            uSnapshot.forEach(udoc => {
                companies.push(udoc.id)
            })
        setCompanies(companies)
        if (requestId) {
            const pSnapshot = await firestore.collection('requests').doc(requestId).get()
            if (pSnapshot.exists) {
                let requestInfo = pSnapshot.data()
                setFlight({
                    'list': {'0': {
                        'departureAirport': requestInfo.departureAirport,
                        'arrivalAirport': requestInfo.arrivalAirport,
                        'departureDate':  utils.getDate(requestInfo.departureDate),
                        'departureTime': requestInfo.departureTime,
                    }},
                    'passengerCnt': requestInfo.passengerCnt,
                    'planeType': requestInfo.planeType,
                    'passengers': requestInfo.passengers,
                    'public': requestInfo.public,
                    'hasPet': requestInfo.hasPet,
                    'numberOfPets': requestInfo.numberOfPets,
                    'weightPerPet': requestInfo.weightPerPet,
                    'number': requestInfo.number
                })
                setStep(3)
            }
        }
    }

    const postRequest = async () => {
        await ApiManager.requestFlight({ uid: currentUser.uid, flight })
            .then((res) => {
                res.data.body.emailsToSend.map((content) => {
                    ApiManager.sendEmail(content);
                })
                utils.pushDataLayer({ event: 'find_a_flight' });
                utils.openNotification('Posted successfully!', true);
            })
    }

    const prevStepHandler = () => {
        if (step === 1)
            history.push('/client/departures')
        if (step > 1)
            setStep(step-1)
        if (step === "AddPassenger")
            setStep(3)
        if (step === "TermsAndConditions") {
            // if (flight.passengerCnt)
            //     setStep(3)
            // else
            //     setStep(4)
            setStep(3)
        }
        if (step === "Review")
            setStep("TermsAndConditions")
    }

    const setFlightValues = (values) => {
        let temp = flight;
        Object.keys(values).forEach(key => {temp[key] = values[key]})
        setFlight(temp)
    }

    const params = {
        history, step, setStep,
        flight, setFlight, setFlightValues,
        activePassenger, setActivePassenger,
        postRequest, currentUser
    }

    let renderPage = ''
    let pageTitle = 'Request a Flight'
    let headerStyle = 'client-header'
    let bodyStyle = 'client-body'

    switch (step) {
        case 1:
            renderPage = <Step1 {...params} />
            headerStyle = 'client-header initial'
            bodyStyle = 'client-body height-100-percent step1'
            break
        case 2:
            renderPage = <Step2 {...params} />
            bodyStyle = 'client-body height-100-percent'
            break
        case 3:
            renderPage = <Step3 {...params} />
            bodyStyle = 'client-body height-100-percent step3'
            break
        // case 4:
        //     renderPage = <Step4 {...params} />
        //     bodyStyle = 'client-body height-100-percent step4'
        //     break
        case 'AddPassenger':
            renderPage = <AddPassenger {...params} />
            pageTitle = 'Passenger'
            bodyStyle = 'client-body height-100-percent addPassenger'
            break
        case 'TermsAndConditions':
            renderPage = <TermsAndConditions {...params} />
            pageTitle = 'HOW IT WORKS'
            headerStyle = 'client-header b-white'
            bodyStyle = 'client-body height-100-percent'
            break
        case 'Review':
            renderPage = <Review {...params} />
            pageTitle = 'Review all Information'
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray height-100-percent review'
            break
        case 'Request':
            renderPage = <Request {...params} />
            pageTitle = ''
            headerStyle = 'client-header b-gray'
            bodyStyle = 'client-body b-gray height-100-percent'
            break
        default:
            renderPage = <Step1 {...params} />
            headerStyle = 'client-header initial'
            bodyStyle = 'client-body height-100-percent step1'
            break
    }

    return (
        <div className="content">
            <div className={headerStyle}>
                <div className="header-item">
                    {pageTitle && <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                    <p>{pageTitle}</p>
                    <div className="step-marker">
                        {typeof(step) == 'number' && <Progress type="circle"
                            trailColor="#ffffff1a"
                            strokeColor="#33CDCD"
                            percent={step/3*100} width={36}
                            format={percent => `${step}/3`}
                        />}
                    </div>
                </div>
            </div>
            <div className={bodyStyle}>
                { renderPage }
            </div>
        </div>
    )
}

export default RequestFlight
